
import { useContext, useState } from "react";
import GlobalState from '../contexts/GlobalState';
import React from 'react';
import Api from "./Api";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import 'swiper/css';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { getIndex } from "../Helpers";
import { AttachIcon, CloseIcon, FileIcon, FilesAddIcon, ShareMailIcon, ShareMailIconHovered, ShareWhatsapIcon, ShareWhatsappIconHovered, ThreeDotsMenu } from "./Icons";


import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { addYears, formatDate } from "../Helpers";
registerLocale('ar', ar)

export default function DocumentsSmallTab(props) {

    const { authUserGlobal, generalDataGlobal, profileClientGlobal } = useContext(GlobalState);
    const [authUser] = authUserGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [generalData] = generalDataGlobal;
    const [, setProfileClient] = profileClientGlobal;

    /* Files Start */
    const [uploadFilePopup, setUploadFilePopup] = useState(false);
    const [fileName, setFileName] = useState('');
    const [fileType, setFileType] = useState('');
    const [fileComment, setFileComment] = useState('');
    const [document, setDocument] = useState();
    const [documentLink, setDocumentLink] = useState();
    // const [tempFile, setTempFile] = useState();
    const [fileMenuOpen, setFileMenuOpen] = useState();
    const [deleteFilePopup, setDeleteFilePopup] = useState();
    const [editingFile, setEditingFile] = useState();
    const [shareLink, setShareLink] = useState();
    const [hoveredSocial, setHoveredSocial] = useState();
    const [copiedText, setCopiedText] = useState();
    const [errorsReturned, setErrorsReturned] = useState({});
    const [fileDate, setFileDate] = useState(new Date());

    //submit files
    const submitFiles = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        fd.append('main_directory', 2);
        fd.append('user_id', props.userId);
        fd.append('folder_id', '');
        if (editingFile?.id) {
            fd.append('file_id', editingFile?.id);
        }

        fd.append('file_date', formatDate(fileDate));
        fd.append('file_name', fileName);
        fd.append('files_type_id', fileType ? fileType?.value : '');
        fd.append('comment', fileComment);

        if (document) {
            fd.append('file', document[0]);
        }
        fd.append('client_id', props.userId);
        fd.append('individual', 1);

        setGeneralDataApiOptions({
            url: 'submit-files',
            method: 'post',
            data: fd,
            callback: res => {
                setProfileClient(res.data);
                setErrorsReturned({});
                setFileType('');
                setFileName('');
                setDocument('');
                setEditingFile('');
                setFileMenuOpen('');
                setDocumentLink('');
                setUploadFilePopup(false);
                setFileComment('');
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    };

    //get all file types
    const allFileTypes = () => {
        const FileTypes = generalData?.files_type?.map(
            singleFile => ({ value: singleFile.id, label: singleFile.title }))
        return FileTypes;
    }

    // reset states on close file popup
    const closeFilePopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setUploadFilePopup(false);
        setFileComment('');
        setFileName('');
        setFileType('');
        setErrorsReturned({});
        setFileMenuOpen(false);
    }

    // custom select styles
    const customStylesFiles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#00366A',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                display: "flex",
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            minHeight: "40px",
            height: "40px",
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "40px",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //document handler
    const documentHandlerFiles = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0])
            // setTempFile(file);
            setDocumentLink(file);
        }
    }

    //files menu clicked
    const openFilesEditMenu = (e, single) => {
        e.stopPropagation();
        e.preventDefault();
        if (fileMenuOpen?.id === single?.id) {
            setFileMenuOpen();
        } else {
            setFileMenuOpen(single);
        }
    }

    //set states with current file data
    const editFile = (e, fileInfo) => {
        e.preventDefault();
        e.stopPropagation();
        setFileComment(fileInfo?.comment ? fileInfo?.comment : '');
        setEditingFile(fileInfo);
        setUploadFilePopup(true);
        setDocumentLink(fileInfo?.full_path_file);
        setFileName(fileInfo?.file_name);
        setFileType(fileInfo?.files_type_id ? allFileTypes()[getIndex(fileInfo?.files_type_id, allFileTypes(), 'value')] : '');
    };


    //delete file process
    const fileDeleteClick = (e, single) => {
        e.preventDefault();
        e.stopPropagation();
        setDeleteFilePopup(single);
    }

    //delete file confirmed
    const deleteFile = (fileId) => {
        setGeneralDataApiOptions({
            url: 'delete-file',
            method: 'post',
            data: {
                file_id: fileId,
                main_directory: 2,
                parent_folder_id: null,
                user_id: props.userId,
                client_id: props.userId,
                individual: 1,
            },
            callback: res => {
                setProfileClient(res.data);
                setErrorsReturned({});
                setFileType('');
                setFileName('');
                setDocument('');
                setDeleteFilePopup(null);
                setEditingFile('');
                setFileMenuOpen('');
                setDocumentLink('');
                setUploadFilePopup(false);
                setFileComment('');
            },
            catch: err => {
                if (err) {
                    setFileMenuOpen(null);
                }
            }
        })
    }


    return (
        <>
            <div className="row py-2">
                <div className="col-6 pb-1">
                    <h1 className="profile-big-title">
                        {generalData?.website_titles['documents-individual']}
                    </h1>
                </div>
                <div className="col-6 pb-1 text-end">
                    <Link className="fs-14 m-0" to={'/client/' + props.userId + '/documents'}>{generalData?.website_titles['view-all']}</Link>
                </div>
                <div className="col-12">
                    <div className="row g-0 p-0 py-2 costs-box-styling custom-shadow px-2">
                        {
                            props.files?.length > 0 ?
                                props.files?.map((singleFile, indexxx) => (
                                    <div key={indexxx} className="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 px-1 py-1 position-relative">
                                        <a href={singleFile?.full_path_file} target="_blank" rel="noreferrer" download={singleFile.title}>
                                            <div className="background-indiv-files h-100 py-3">
                                                <div className="row justify-content-center">
                                                    <div className="col-auto">
                                                        <FileIcon />
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-auto">
                                                        <p className="m-0 word-break px-2 pt-2">{singleFile?.file_name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <ThreeDotsMenu className="cursor-pointer file-buttons" onClick={(e) => openFilesEditMenu(e, singleFile)} />
                                            <div className={"settings-clickmenu text-start " + (singleFile?.id === fileMenuOpen?.id ? ' d-block' : '')}>
                                                {
                                                    singleFile?.files_structure?.folder_slug === 'signatures' ||
                                                        singleFile?.files_structure?.folder_slug === 'proxies' ||
                                                        singleFile?.files_structure?.folder_slug === 'personal-documents' ||
                                                        singleFile?.files_structure?.folder_slug === 'shares-operations' ||
                                                        singleFile?.files_structure?.folder_slug === 'board-members' ||
                                                        (authUser?.user_role_id > 2 && (authUser?.id !== singleFile.create_user_id)) ?
                                                        <>
                                                            <p onClick={(e) => { e.stopPropagation(); e.preventDefault(); setShareLink(singleFile) }} style={{ zIndex: "150" }} className="p-0 py-1 m-0 ms-2">{generalData?.documentsSectionSettings['share-placeholder']}</p>
                                                        </>
                                                        :
                                                        <>
                                                            <p onClick={(e) => editFile(e, singleFile)} style={{ zIndex: "99" }} className="p-0 py-1 m-0 ms-2">{generalData?.documentsSectionSettings['edit-placeholder']}</p>
                                                            <p onClick={(e) => { e.stopPropagation(); e.preventDefault(); setShareLink(singleFile) }} style={{ zIndex: "150" }} className="p-0 py-1 m-0 ms-2">{generalData?.documentsSectionSettings['share-placeholder']}</p>
                                                            <p onClick={(e) => fileDeleteClick(e, singleFile)} style={{ zIndex: "200" }} className="p-0 py-1 m-0 ms-2">{generalData?.documentsSectionSettings['delete-placeholder']}</p>
                                                        </>
                                                }
                                            </div>
                                        </a>
                                    </div>
                                ))
                                :
                                null
                        }
                        <div onClick={() => setUploadFilePopup(true)} className="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 px-1 py-1 ">
                            <div className="add-files-individual">
                                <FilesAddIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                //share link popup
                shareLink ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShareLink(); setCopiedText(); setErrorsReturned({}); setEditingFile(); setFileMenuOpen() }} />
                            <div className=" ">
                                <div className="containter-fluid">
                                    <div className="row">
                                        <div className="col-12 text-start">
                                            <h1 className="pop-up-title m-0 py-2 color-dark-blue share-title-popup">{generalData?.documentsSectionSettings['share-link-title']}</h1>
                                        </div>
                                        <div className="col-12 d-flex py-1">
                                            <div className="share-icons pe-2" onMouseLeave={() => setHoveredSocial()} onMouseEnter={() => setHoveredSocial(1)}>
                                                <a href={'whatsapp://send?text=' + shareLink?.full_path_file + ''} target="_blank" rel="noreferrer">
                                                    {
                                                        hoveredSocial === 1 ?
                                                            <ShareWhatsappIconHovered />
                                                            :
                                                            <ShareWhatsapIcon />
                                                    }
                                                </a>
                                            </div>
                                            <div className="share-icons" onMouseLeave={() => setHoveredSocial()} onMouseEnter={() => setHoveredSocial(2)}>
                                                <a href={'mailto:?body=' + shareLink?.full_path_file} target="_blank" rel="noreferrer">
                                                    {
                                                        hoveredSocial === 2 ?
                                                            <ShareMailIconHovered />
                                                            :
                                                            <ShareMailIcon />
                                                    }
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 text-start">
                                            <h1 className="pop-up-title m-0 py-2 color-dark-blue share-title-popup">{generalData?.documentsSectionSettings['copy-link-title']}</h1>
                                        </div>

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col">
                                                    <input name="link" className="auth-input link-copy" value={shareLink?.full_path_file} />
                                                </div>
                                                <div className="col-auto my-auto">
                                                    <button onClick={(e) => { e.preventDefault(); setCopiedText(shareLink?.full_path_file); navigator.clipboard.writeText(shareLink?.full_path_file) }} type="button" className="popup-button my-auto">{copiedText ? generalData?.documentsSectionSettings['text-copied'] : generalData?.documentsSectionSettings['copy-link-button']}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }

            {
                // delete file popup
                deleteFilePopup ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={() => { setDeleteFilePopup(null); setFileMenuOpen(null) }} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-9 col-sm-12 d-flex justify-content-center">
                                            <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                            <button onClick={() => deleteFile(deleteFilePopup ? deleteFilePopup?.id : null)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                            <button onClick={() => { setDeleteFilePopup(null); setFileMenuOpen(null) }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }


            {
                //upload file popup
                uploadFilePopup ?

                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={(e) => closeFilePopup(e)} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <h1 className="pop-up-title m-0 py-2 color-dark-blue">{editingFile?.id ? generalData?.documentsSectionSettings['edit-file-title'] : generalData?.documentsSectionSettings['upload-file-title']}</h1>
                                        </div>
                                        <form onSubmit={submitFiles}>
                                            <div className="col-12 pt-2 pb-4">
                                                <div className="row justify-content-start">
                                                    <div className="col-12 col-md-6 pt-2 pe-md-0">
                                                        <div className="postion-relative d-flex justify-content-start w-100">
                                                            <Select
                                                                classNamePrefix="popup-select"
                                                                className="popup-select w-100"
                                                                styles={customStylesFiles}
                                                                options={allFileTypes()}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                placeholder={generalData?.documentsSectionSettings['type-placeholder']}
                                                                isSearchable={false}
                                                                value={fileType ? fileType : null}
                                                                onChange={setFileType} />
                                                        </div>
                                                        {
                                                            errorsReturned?.files_type_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.files_type_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input name="file_name" className="add-user-inputs" placeholder={generalData?.documentsSectionSettings['file-name-placeholder']} value={fileName} onChange={(e) => setFileName(e.target.value)} />
                                                        {
                                                            errorsReturned?.file_name ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file_name}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2 pe-md-0 position-relative">
                                                        <input hidden id='upload-document-file' type="file" onChange={(e) => { documentHandlerFiles(e.target.files) }} />
                                                        <label className="pop-upload-file position-relative" htmlFor="upload-document-file">{documentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}</label>

                                                        <AttachIcon />
                                                        {
                                                            errorsReturned?.file ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2" >
                                                        <div className="position-relative text-start justify-content-start w-100">
                                                            <DatePicker className="popup-date add-user-inputs"
                                                                placeholderText={generalData?.website_titles['date']}
                                                                value={fileDate ? fileDate : ''}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                selected={fileDate || ''}
                                                                dateFormat="d MMMM yyyy"
                                                                onChange={(date) => { setFileDate(date); }}
                                                                minDate={addYears(new Date(), -100)}
                                                                maxDate={addYears(new Date(), +100)}
                                                                locale={localStorage.getItem("BlpLanguage")}
                                                            />
                                                        </div>
                                                        {
                                                            errorsReturned?.transaction_date ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.transaction_date}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 pt-2">
                                                        <textarea type="text-area" name="file_comment" className="add-user-inputs" placeholder={generalData?.documentsSectionSettings['comment-placeholder']} value={fileComment} onChange={(e) => setFileComment(e.target.value)} />
                                                        {
                                                            errorsReturned?.file_comment ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file_comment}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                                <button type="submit" className="popup-button my-auto">{editingFile?.id ? generalData?.documentsSectionSettings['change-file-button'] : generalData?.documentsSectionSettings['add-file-button']}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            <Api options={generalDataApiOptions} />
        </>
    )
}