import { useRef } from 'react';
import { useContext, useEffect, useState } from 'react';
import GlobalState from '../contexts/GlobalState';
import useOutsideClick from '../useOutsideClick';
import Api from './Api';
import { UserSelectClose } from './Icons';


//formate date YYYY-MM-DD
const formatDate = (date) => {
    return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + Number(Number(date.getMonth()) + 1) : Number(date.getMonth()) + 1}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
}

export default function UsersSelect(props) {
    const { generalDataGlobal, profileClientGlobal } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [generalData,] = generalDataGlobal;

    const [allUsers, setAllUsers] = useState({});
    const [actualUsersData, setActualUsersData] = useState({});
    const [search, setSearch] = useState('');
    const [changeInput, setChangeInput] = useState(0);

    const { className, editable, addPadding, fieldName } = props;
    const assigneeRef = useRef();

    useOutsideClick(assigneeRef, () => {
        props.setActiveEditUsers(null)
    })
    //reset actual users to initial prop state
    useState(() => {
        setActualUsersData(props?.users);
    }, [props?.activeEditUsers]);// eslint-disable-line react-hooks/exhaustive-deps

    //reset actual users to initial prop state
    useEffect(() => {
        setActualUsersData(props?.users);
    }, [profileClient]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setActualUsersData(props?.users)
    }, [props?.users])
    //handle search
    useEffect(() => {
        if (search) {
            let newUsersFiltered = [];
            let searchToLower = search.toLowerCase();
            if (allUsers) {
                generalData?.users.forEach(singleUser => {
                    if (!search || search === '' ||
                        (
                            singleUser.name.toString().toLowerCase().includes(searchToLower)
                        ) ||
                        (
                            singleUser?.name_ar?.toString()?.toLowerCase()?.includes(searchToLower)
                        )
                    ) {
                        newUsersFiltered.push(singleUser);
                    }
                });
            }
            setAllUsers(newUsersFiltered);
        } else {
            setTimeout(() => {
                setAllUsers(actualUsersData);
            }, 200)
        }
    }, [props.setActiveEditUsers, profileClient, search, actualUsersData]);// eslint-disable-line react-hooks/exhaustive-deps

    //reset search
    useEffect(() => {
        setSearch('');
    }, [props.activeEditUsers]);// eslint-disable-line react-hooks/exhaustive-deps

    //add to actual users and trigger backend call
    const addToActualUsers = (e, value) => {
        e.preventDefault();
        e.stopPropagation();
        let newUsersArr = [];
        newUsersArr = [...actualUsersData];
        let i = 0;
        if (newUsersArr?.length === 0) {

            newUsersArr.push(value);
        } else {
            newUsersArr?.forEach((single) => {
                if (single?.id === value?.id) {

                    i = 1;
                }
            })
            if (i === 0) {
                newUsersArr.push(value);
            }
        }
        if (i === 0) {

            setActualUsersData('');
            setActualUsersData(newUsersArr);
            setChangeInput(changeInput + 1);
        }
    }

    //backend post
    useEffect(() => {
        if (changeInput > 0) {
            let fd = new FormData();
            if (props?.data?.id && props.url === 'edit-users-reminder') {
                fd.append('reminder_id', props?.data?.id);
                fd.append('client_id', props?.clientId);
                fd.append('user_id', props?.userId);
                fd.append('reminder_date_selected', props.selectedDate ? formatDate(props.selectedDate) : '');
            } else if (props?.data?.id && props.url === 'edit-license-users') {
                if (props?.data?.id) {
                    fd.append('license_id', props?.data?.id);
                }
                fd.append('client_id', props?.clientId);
                fd.append('user_id', props?.clientId);
            } else {
                fd.append(props?.targetName || 'target_id', props?.data?.id)
                fd.append('model', props?.model);
            }

            if(props?.returnUser){
                fd.append('return_user', props?.returnUser );
            }

            fd.append('type', props?.type || '');

            if (props?.return) {
                fd.append('return', props?.return);
            }

            if(props?.tasksType){
                fd.append('tasksType',props?.tasksType);
            }

            let remind_users = [];
            actualUsersData?.forEach((single) => {
                remind_users.push(single?.id);
            });

            fd.append(fieldName || 'remind_users', remind_users);
            fd.append('field_name', fieldName || '')
            if(props?.page){
                fd.append('page', props?.page)
            }
            if(props?.sort){
                fd.append('sort', props?.sort)
            }
            if(props?.direction){
                fd.append('direction', props?.direction)
            }
            if(props?.returnDashboard && props?.returnDashboard === 1){
                fd.append('return_dashboard',1);
            }
            if(props?.returnSingleTask && props?.returnSingleTask === 1){
                fd.append('return_single_task', 1);
            }

            setGeneralDataApiOptions({
                url: props.url,
                method: 'post',
                data: fd,
                callback: res => {
                    if (props.url === 'edit-users-reminder' || props.url === 'edit-license-users') {
                        setProfileClient(res?.data);
                    }
                    props?.onChange(res.data)
                },
                catch: err => {
                    if (err) {
                    }
                }
            });
        }
    }, [changeInput]);// eslint-disable-line react-hooks/exhaustive-deps

    //remove from actual users
    const removeFromActiveUsers = (e, value) => {
        e.preventDefault();
        if (actualUsersData?.length > 1 || props?.allowDeleteAll === 1) {
            setActualUsersData((current) =>
                current.filter(
                    (user) => user.id !== value
                )
            );
            setChangeInput(changeInput + 1);
        }
    }

    //validate if in actual users
    const inActualUsers = (value) => {
        let newUsersArr = actualUsersData;
        if (newUsersArr?.length > 0) {
            let i = 0;
            newUsersArr?.forEach((single) => {
                if (single?.id === value) {
                    i = 1;
                }
            })
            if (i === 1) {
                return 1;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    return (
        <>
            <div className={`d-flex ${className ? className : ''}`}>
                {
                    actualUsersData?.length > 0 ?
                        actualUsersData?.map((singleUser, userIndex) => (
                            <div width="30" height="30" style={{ width: '30px', height: '30px' }} key={userIndex} className={`position-relative d-flex user-image-in-table ${(userIndex === 0 ? '' : 'back-images')} ${addPadding ? '' : 'ps-0'}` } >
                                <img style={{ width: '30px', height: '30px' }} className="table-user-image" src={singleUser?.picture ? singleUser?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                                <div className="triangle-down"></div>
                                <p className="hover-username">{singleUser?.name}</p>
                            </div>
                        ))
                        :
                        null
                }
                {
                    editable === false ?
                        <></>
                        :
                        <div style={{ width: '30px', height: '30px' }} className={'ps-0 position-relative d-flex user-image-in-table back-images'} >
                            <img ref={assigneeRef} onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.setActiveEditUsers(props.activeEditUsers?.id === props.data?.id ? null : props.data) }} style={{ width: '31px', height: '31px' }} className="table-user-image" src="/assets/images/add-users.svg" alt="add-user" />
                            {
                                props.activeEditUsers ?
                                    null
                                    :
                                    <>
                                        <div className="triangle-down"></div>
                                        <p className="hover-username">Add</p>
                                    </>
                            }
                            {
                                props?.activeEditUsers?.id === props.data?.id ?
                                    <div onClick={(e) => { e.preventDefault(); e.stopPropagation() }} className="search-custom-users">
                                        <input className="search-users-custom-select" value={search} onChange={(e) => setSearch(e.target.value)} />
                                        {
                                            allUsers?.length > 0 ?
                                                allUsers?.map((singleEl, indd) => (
                                                    <div onClick={(e) => { e.stopPropagation(); e.preventDefault(); addToActualUsers(e, singleEl) }} key={indd} className="m-0 fs-12 pb-1">
                                                        <div className="position-relative delete-click-select-users">
                                                            <img style={{ width: '25px', height: '25px' }} className="me-1 table-user-image" src={singleEl?.picture ? singleEl?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                                                            {
                                                                actualUsersData && inActualUsers(singleEl?.id) === 1 ?
                                                                    <UserSelectClose onClick={(e) => { e.stopPropagation(); e.preventDefault(); removeFromActiveUsers(e, singleEl?.id); }} className="delete-icon-users-select" />
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        {singleEl?.name}
                                                    </div>
                                                ))
                                                :
                                                null
                                        }
                                    </div>
                                    :
                                    null
                            }
                        </div>
                }
            </div>

            <Api options={generalDataApiOptions} />
        </>
    )
}