import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { useNavigate, useParams } from "react-router";
import Select from 'react-select';
import React from 'react';
import Api from "./Api";
import EditInput from "./EditInput";
import "react-datepicker/dist/react-datepicker.css";
import 'swiper/css';
import DocumentsSmallTab from "./DocumentsSmallTab";
import { Link } from "react-router-dom";
import ShareHoldersManageComponent from "./ShareHolderManageComponent";
import ShareOperationManageComponent from "./ShareOperationManageComponent";
import showCommaSeperator from "../useCommaSeparator";
import { getIndex } from "../Helpers";

import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { CloseIcon } from "./Icons";
registerLocale('ar', ar)

export default function Shareholders(props) {

    const {
        authUserGlobal,
        generalDataGlobal,
        changeInputGlobal,
        currentInputGlobal,
        currentInputValueGlobal,
        currentInputTypeGlobal,
        profileClientGlobal
    } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [authUser] = authUserGlobal;
    let { slug, user_id } = useParams();
    const [generalData] = generalDataGlobal;
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [currentInput, setCurrentInput] = currentInputGlobal;
    const [currentInputValue, setCurrentInputValue] = currentInputValueGlobal;
    const [currentInputType, setCurrentInputType] = currentInputTypeGlobal;
    const history = useNavigate();
    const [errorsReturned, setErrorsReturned] = useState({});
    const [changeInput, setChangeInput] = changeInputGlobal;
    const [formData, setFormData] = useState({});
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [selectedType, setSelectedType] = useState();
    const [isShareholdersPopupOpen, setIsShareholdersPopupOpen] = useState(false);
    const [isShareOperationPopupOpen, setIsShareOperationPopupOpen] = useState(false);
    const [errorPopupOpen, setErrorPopupOpen] = useState()


    //custom select styles
    const customStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '16px',
                color: '#707070 !important',
                border: 'none !important',
                minHeight: "26px",
                maxHeight: "26px",
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                display: "flex",
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#707070",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            minHeight: "26px",
            maxHeight: "26px",
            textTransform: "capitalize",
        }),
        control: (base, provided) => ({
            ...base,
            border: "0 !important",
            boxShadow: 'none !important',
            minHeight: '26px !important',
            height: '26px !important',
            '&:hover': { borderColor: 'transparent', border: 'none' },
            backgroundColor: 'white',
            outline: "none",
            width: "190px",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '16px',
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
            maxWidth: '250px',
            marginTop: '0px',
            boxShadow: '0px 3px 6px rgb(0 0 0 / 16%)',
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            padding: "4px",
            paddingTop: "1px",
        }),
        container: (base) => ({
            ...base,
            height: "26px",
            minHeight: "26px",
            maxHeight: "26px",
        }),
        valueContainer: (base) => ({
            ...base,
            height: "26px",
            minHeight: "26px",
            maxHeight: "26px",
        }),
        input: (base, state) => ({
            ...base,
            backgroundColor: "transparent !important",
            width: 'auto',
            textTransform: "none !important",
            border: "none !important",
            color: "#707070",
            fontSize: "16px",
            height: "23px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //on slug change
    useEffect(() => {
        if (slug) {
            history('/client/' + user_id + '/' + slug);
        }
    }, [slug]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedCurrency) {
            setCurrentInput('shares_currency');
            setCurrentInputValue(selectedCurrency.value);
        }
    }, [selectedCurrency]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedType) {
            setCurrentInput('share_type');
            setCurrentInputValue(selectedType.value);
        }
    }, [selectedType]);// eslint-disable-line react-hooks/exhaustive-deps

    //handle changes in data using the dynamic inputs and post them  to backend
    useEffect(() => {
        if (changeInput > 0 && slug === 'shareholders') {
            let fd = new FormData();

            fd.append('current_user', user_id);
            fd.append('current_field', currentInput);
            if (currentInputType === 'number') {
                fd.append('current_field_value', currentInputValue.replaceAll(',', ''));
            } else {
                fd.append('current_field_value', currentInputValue);
            }

            fd.append('client_id', user_id);
            fd.append('user_id', user_id);
            fd.append('shares_currency', selectedCurrency ? selectedCurrency.value : null);

            setGeneralDataApiOptions({
                url: 'edit-client-data',
                method: 'post',
                data: fd,
                callback: res => {
                    setErrorsReturned({});
                    setFormData(res?.data);
                    setProfileClient(res.data);
                    setCurrentInputValue('');
                    setCurrentInput('');
                    setCurrentInputType('');
                    setErrorsReturned({});
                    setChangeInput(0);
                },
                catch: err => {
                    if (err) {
                        setErrorsReturned(err);
                        setChangeInput(0);
                    }
                }
            })
        }
    }, [changeInput]);// eslint-disable-line react-hooks/exhaustive-deps


    //set page data when profileclient changes or user_id changes
    useEffect(() => {
        setFormData(profileClient);
    }, [profileClient, user_id]);// eslint-disable-line react-hooks/exhaustive-deps


    const allCurrencies = () => {
        const currenciesOptions = generalData?.currencies?.map(
            singleCurrency => ({ slug: singleCurrency.slug, value: singleCurrency.id, label: singleCurrency.title }))
        return currenciesOptions;
    }
    const allShareTypes = () => {
        const typeOptions = generalData?.share_types?.map(
            singleShareType => ({ value: singleShareType.id, label: singleShareType.title }))
        return typeOptions;
    }

    useEffect(() => {
        setSelectedCurrency(allCurrencies()[getIndex(profileClientGlobal[0].currency_id, allCurrencies(), 'value')])
        setSelectedType(allShareTypes()[getIndex(profileClientGlobal[0].share_type_id, allShareTypes(), 'value')])
    }, [profileClientGlobal.shares_currency]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleAddShareHoldersClick = () => {
        if (profileClient.capital && profileClient.share_values && profileClient.total_issued_shares) {
            setIsShareholdersPopupOpen(true);
        } else {
            setErrorPopupOpen(generalData?.shareholdersSectionSettings['error-popup']);
        }
    }

    const handleShareOperationClick = () => {
        if (profileClient.capital && profileClient.share_values && profileClient.total_issued_shares) {
            setIsShareOperationPopupOpen(true)
        } else {
            setErrorPopupOpen(generalData?.shareholdersSectionSettings['error-popup']);
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row client-profile-height">

                    {/* registration section starts */}
                    <div className="col-12 col-lg-3 pt-4 drop-shadow-div scrollable-client-profile-height">
                        <div className="row">
                            <div className="col-auto">
                                <h1 className="profile-big-title m-0">{generalData?.website_titles['capital--shares']}</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 my-auto align-items-center pb-4">
                                <div className="row">
                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <p className="profile-general-input-title">{generalData?.website_titles['capital']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                {
                                                    profileClient?.shareholders?.length !== 0 ?
                                                        <p>{showCommaSeperator(formData?.capital)}</p>
                                                        :
                                                        <EditInput commaSep inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.capital ? formData?.capital : ''} inputName={"capital"} userId={user_id} placeholder={generalData?.website_titles['capital']} />
                                                }
                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.capital ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className="pop-errors-text">{errorsReturned?.capital}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <p className="profile-general-input-title">{generalData?.website_titles['currency']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                {
                                                    profileClient?.shareholders?.length !== 0 ?
                                                        <p>{profileClient?.currency?.title}</p>
                                                        :
                                                        <Select
                                                            isSearchable={true}
                                                            classNamePrefix="profile-select"
                                                            className="profile-select w-100 my-auto"
                                                            styles={customStyles}
                                                            options={allCurrencies()}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            placeholder={generalData?.website_titles['currency']}
                                                            value={selectedCurrency ? selectedCurrency : allCurrencies()[getIndex('lbp', allCurrencies(), 'slug')]}
                                                            onChange={(e) => { setSelectedCurrency(e); setTimeout(() => { setChangeInput(1) }, 100); setCurrentInputType('') }}
                                                        />
                                                }

                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.registration_city ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className="pop-errors-text">{errorsReturned?.registration_city}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <p className="profile-general-input-title">{generalData?.website_titles['share-values']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-8 pt-1">
                                                {
                                                    profileClient?.shareholders?.length !== 0 ?
                                                        <p>{showCommaSeperator(formData?.share_values)}</p>
                                                        :

                                                        <EditInput commaSep inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.share_values ? formData?.share_values : ''} inputName={"share_values"} userId={user_id} placeholder={generalData?.website_titles['share-values']} />
                                                }
                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.share_values ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className="pop-errors-text">{errorsReturned?.share_values}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <p className="profile-general-input-title">{generalData?.website_titles['total-issued-shares']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                {
                                                    profileClient?.shareholders?.length !== 0 ?
                                                        <p>{showCommaSeperator(formData?.total_issued_shares)}</p>
                                                        :
                                                        <EditInput commaSep inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.total_issued_shares ? formData?.total_issued_shares : ''} inputName={"total_issued_shares"} userId={user_id} placeholder={generalData?.website_titles['total-issued-shares']} />
                                                }
                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.total_issued_shares ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className="pop-errors-text">{errorsReturned?.total_issued_shares}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* registration section ends */}

                    <div className="col-12 col-lg-9 pt-4 px-lg-4 pb-5 scrollable-client-profile-height">
                        {/* table section starts  */}
                        <div className="row pb-3">
                            <div className="col-sm-6 pb-2">
                                <div className="w-100 h-100 d-flex ">
                                    <h3 className="profile-big-title color-dark-blue my-auto">{generalData?.website_titles['share--reports']}</h3>
                                </div>
                            </div>
                            {
                                authUser?.user_role_id <= 2 ?
                                    <div className="col-sm-6 justify-content-center align-items-center py-2">
                                        <div className="row justify-content-end">
                                            <div className="col-xl-4 col-md-6 col-sm-6">
                                                <button onClick={handleAddShareHoldersClick} className="popup-button my-auto mx-auto w-100">{generalData?.fixed_titles['add-shareholder']}</button>
                                            </div>
                                            <div className="col-xl-4 col-md-6 col-sm-6 mt-3 mt-sm-0">
                                                <button onClick={handleShareOperationClick} className="popup-button my-auto mx-auto w-100">{generalData?.fixed_titles['shares-operation']}</button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <div className="col-12">
                                <div style={{ height: "250px" }} className="table-wrapper-settings custom-shadow">

                                    <table className="users-table" width="100%">
                                        <thead>
                                            <tr>
                                                <td style={{ minWidth: "200px" }}>{generalData?.website_titles['shareholder-type']}</td>
                                                <td style={{ minWidth: "200px" }}>{generalData?.website_titles['shareholder-name']}</td>
                                                <td style={{ minWidth: "200px" }}>{generalData?.website_titles['number-of-shares']}</td>
                                                <td style={{ minWidth: "200px" }}>{generalData?.website_titles['percentage']}</td>
                                                <td style={{ minWidth: "200px" }}>{generalData?.website_titles['shares-value']}</td>
                                                <td style={{ minWidth: "250px" }}>{generalData?.website_titles['comment']}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                profileClient?.shareholders?.length > 0 &&
                                                profileClient?.shareholders?.map((shareholder, index) => (

                                                    ((shareholder?.number_of_shares !== 0) || (shareholder?.preffered_shares !== 0)) &&
                                                    <tr key={index}>
                                                        <td>{shareholder.client?.client_type.title}</td>
                                                        <td>{shareholder.client?.name}</td>
                                                        <td>
                                                            {shareholder?.number_of_shares ? shareholder?.number_of_shares : 0}
                                                        </td>
                                                        <td>{parseFloat((parseInt(shareholder?.number_of_shares ? shareholder?.number_of_shares : 0) + parseInt(shareholder?.preffered_shares ? shareholder?.preffered_shares : 0)) / profileClient.total_issued_shares * 100).toFixed(2)} %</td>
                                                        <td>{showCommaSeperator(profileClient.share_values * (parseInt(shareholder?.number_of_shares ? shareholder.number_of_shares : 0) + parseInt(shareholder?.preffered_shares ? shareholder?.preffered_shares : 0)))}</td>
                                                        <td>
                                                            {
                                                                shareholder.comment ?
                                                                    shareholder?.comment?.length > 20 ?
                                                                        <>
                                                                            <div className="position-relative user-image-in-table">
                                                                                <span >
                                                                                    <>{`${shareholder?.comment.substring(0, 20)}...`}</>
                                                                                </span>
                                                                                {

                                                                                    <>
                                                                                        <div className="triangle-down"></div>
                                                                                        <p className="hover-username" style={{ maxWidth: '100%', fontSize: '22px !important' }}>{shareholder?.comment}</p>
                                                                                    </>

                                                                                }
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        shareholder?.comment
                                                                    :
                                                                    '-'
                                                            }
                                                        </td>
                                                    </tr>


                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    {
                                        profileClient?.shareholders?.length === 0 &&
                                        <>
                                            <div className="w-100 text-center mt-4">
                                                {generalData?.fixed_titles['you-didnt-add-any-company-yet'].replace('%record%', generalData?.website_titles['shareholders'])}
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="w-100 shareholder-totals">
                                    <div className="row justify-content-center align-items-center h-100 py-1">
                                        <div className="col-12 col-md-6 d-flex justify-content-center h-100 border-shareholders-total">
                                            <div className="row justify-content-center align-items-center">
                                                <div className="col-12 d-flex justify-content-center">
                                                    <p className="m-0">{generalData?.website_titles['total-shares']}: {profileClient?.shareholders?.length > 0 ? profileClient?.shareholders?.reduce((sum, item) => Number(sum) + Number(item.number_of_shares), 0) : 0}</p>
                                                </div>
                                                {/* <div className="col-12 d-flex justify-content-center">
                                                    <p className="m-0">+-Total Number of Shares:</p>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 d-flex justify-content-center">
                                            <p className="m-0">{generalData?.website_titles['total-percentage']}: {profileClient?.shareholders?.length > 0 ? (((profileClient?.shareholders?.reduce((sum, item) => Number(sum) + Number(item.number_of_shares), 0)) / Number(formData?.total_issued_shares)) * 100).toFixed(2) + '%' : '0%'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* table section starts  */}
                        {
                            authUser?.user_role_id <= 2 ?
                            <div className="row mb-3">
                                <div className="col-auto">
                                    <Link to={'/client/' + user_id + '/shareholders/transactions'} className="popup-button my-auto mx-auto w-100 px-4">{generalData?.fixed_titles['view-transactions']}</Link>
                                </div>
                            </div>
                            :
                            null
                        }

                        <DocumentsSmallTab userId={user_id} files={profileClient?.home_files} />
                    </div>
                </div>
            </div>
            {
                isShareholdersPopupOpen &&
                <ShareHoldersManageComponent setIsShareholdersPopupOpen={setIsShareholdersPopupOpen} />
            }
            {
                isShareOperationPopupOpen &&
                <ShareOperationManageComponent setIsShareOperationPopupOpen={setIsShareOperationPopupOpen} />
            }

            {
                //delete contact popup
                errorPopupOpen &&
                <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                    <div className="pop-up-api-box">
                        <CloseIcon className="cursor-pointer" onClick={(e) => { setErrorPopupOpen() }} />
                        <div className="text-center">
                            <div className="containter-fluid">
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-9 col-sm-12 d-flex justify-content-center">
                                        <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{errorPopupOpen}</h1>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                        <button onClick={(e) => { setErrorPopupOpen() }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }


            <Api options={generalDataApiOptions} />
        </>
    )
}