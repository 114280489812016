import React, { useState, useRef, useEffect, useContext } from 'react';
import GlobalState from '../contexts/GlobalState';
import EntityContactCard from './EntityContactCard';
import { AccordionArrow, FilesAddIcon } from './Icons';

export default function EntityContacts(props) {
    const { authUserGlobal, generalDataGlobal, entityContactPopupGlobal, deleteContactPopupGlobal, profileClientGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [authUser] = authUserGlobal;
    const [height, setHeight] = useState(0);
    const elementRef = useRef(null);
    const [profileClient,] = profileClientGlobal;
    const [entityContactPopup, setEntityContactPopup] = entityContactPopupGlobal;
    const [openCardId, setOpenCardId] = useState();
    const [activeMenu, setActiveMenu] = useState({});
    const [deleteContactPopup,] = deleteContactPopupGlobal;
    const [cardHeight, setCardHeight] = useState(0);
    const [addedHeight, setAddedHeight] = useState(0);
    const [initialSize, setInitialSize] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);

    const { count } = props;

    //update inner width end to calculate div initial height when width changes
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        setInitialSize(elementRef?.current?.clientHeight);
    }, [width, profileClient]);
    //update inner width end

    // reset active menu when card is changed
    useEffect(() => {
        setActiveMenu({});
    }, [openCardId]);

    //change height
    useEffect(() => {
        if (!openCardId) {
            setHeight(initialSize);
        } else {
            setHeight(initialSize + cardHeight);
        }
    }, [cardHeight, addedHeight, openCardId, profileClient, initialSize]);// eslint-disable-line react-hooks/exhaustive-deps

    //handle active menu reset
    useEffect(() => {
        setActiveMenu({});
    }, [entityContactPopup, deleteContactPopup]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div
                className={`faqs position-relative ${props?.singleRem?.id === Number(props.openId) ? ' active ' : ''} ${props?.indRem !== 0 ? ' mt-3 ' : ''}`}
                onClick={() => {
                    props.data?.id === Number(props.openId) ? props.setOpenId(null) : props.setOpenId(props.data?.id);
                    setHeight(elementRef.current.clientHeight);
                }}
            >
                <div
                    className={'accordion-title'}
                    onClick={() => {
                        props.setOpenId(Number(props.openId) === props.data?.id ? null : props.data?.id)
                    }}
                >
                    <div className="row justify-content-between position-relative">
                        <div className="col-12">
                            <h3 className="cursor-pointer profile-big-title d-flex align-items-center m-0">
                                {props?.data?.title}
                                {
                                    (authUser?.user_role_id > 2 && props?.contactsData?.length === 0 ) ?
                                    null
                                    :
                                    <AccordionArrow style={{ rotate: Number(props?.openId) === props?.data?.id ? '' : '180deg' }} className="ms-2 " />
                                }
                                {
                                    authUser?.user_role_id <= 2 ?
                                        <img className=' shadow mx-3' style={{ zIndex: '9999999' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setEntityContactPopup({ "entity_contacts_types": { "title": props?.data?.title }, "client_id": props?.clientId, "id": 0, "entity_contacts_types_id": props?.data?.id }) }} src={'/assets/images/plus.svg'} alt="add" />
                                        :
                                        null
                                }
                                {
                                    !!count &&
                                    <p className="ms-2" style={{ fontbSize: 12, marginBottom: 0, fontWeight: 'lighter' }}>({props.contactsData.length})</p>
                                }
                            </h3>
                        </div>
                    </div>
                    <div className="col-12">
                        <div style={{ height: Number(props.openId) === props.data?.id ? height : 0 }} className={Number(props.openId) === props.data?.id ? 'accordion-content open' : 'accordion-content '}>
                            <div ref={elementRef} className="row">
                                {
                                    props?.contactsData?.length > 0 ?
                                        props?.contactsData?.map((singleContact, contactInd) => (
                                            <>
                                                <EntityContactCard addedHeight={addedHeight} setAddedHeight={setAddedHeight} cardHeight={cardHeight} setCardHeight={setCardHeight} activeMenu={activeMenu} setActiveMenu={setActiveMenu} data={singleContact} key={contactInd} openCardId={openCardId} setOpenCardId={setOpenCardId} />
                                            </>
                                        ))
                                        :
                                        <>
                                            {
                                                authUser?.user_role_id <= 2 ?
                                                    <div className="col-12 col-md-6 py-2" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setEntityContactPopup({ "entity_contacts_types": { "title": props?.data?.title }, "client_id": props?.clientId, "id": 0, "entity_contacts_types_id": props?.data?.id }) }}>
                                                        <div className="no-entity-data border-dashed">
                                                            <div className="cms-table-content position-relative h-100">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="gray-background">
                                                                            <div className="row py-2 px-3">
                                                                                <div className="col-auto">
                                                                                    <img className="image-user-chat" src="/assets/images/no-entity-contact.svg" alt="chat user" />
                                                                                </div>
                                                                                <div className="col ps-0">
                                                                                    <div className="row justify-content-between">
                                                                                        <div className="col-auto">
                                                                                            <p className="m-0 font-semi-bold">{props?.data?.title}</p>
                                                                                            <h5 className="locked-inputs w-100 fs-14">{generalData?.website_titles['contact-type']}</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row py-2">
                                                                <div className="col-12 py-4 d-flex justify-content-center align-items-center">
                                                                    <FilesAddIcon />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}