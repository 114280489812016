import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { useParams } from "react-router";
import React from 'react';
import Api from "./Api";
import EditInput from "./EditInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import 'swiper/css';
import ClientComments from "./ClientComments";


import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { formatDateTime, getOptions } from "../Helpers";
import UsersSelect from "./UsersSelect";
import StatusComponent from "./StatusComponent";
import PriorityComponent from "./PriorityComponent";
import RelationsSelect from "./RelationsSelect";
import TaskHistoryComponent from "./TaskHistoryComponent";
import { getIndex } from '../Helpers';

registerLocale('ar', ar)

export default function TaskGeneralInfo(props) {

    const {
        authUserGlobal: [authUser],
        generalDataGlobal: [generalData],
        changeInputGlobal,
        currentInputGlobal,
        currentInputValueGlobal,
        currentInputValidationsGlobal,
        activeMatter
    } = useContext(GlobalState);

    const { activeTask, setActiveTask } = props;

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    let { slug, user_id } = useParams();
    // const [generalData] = generalDataGlobal;
    const [usersApiOptions,] = useState({});
    const [currentInput, setCurrentInput] = currentInputGlobal;
    const [currentInputValue, setCurrentInputValue] = currentInputValueGlobal;
    const [currentInputValidations,] = currentInputValidationsGlobal;
    const { tasksStatuses, tasksPriorities } = generalData;
    const [changeInput, setChangeInput] = changeInputGlobal;

    const [errorsReturned, setErrorsReturned] = useState({});

    const [selectedTaskType, setSelectedTaskType] = useState();
    const [dueDate, setDueDate] = useState();
    const [selectedRelation, setSelectedRelation] = useState();

    const [, setReturnedFiles] = useState({});

    const [clientType,] = useState(activeTask?.client?.clientType);

    const [taskHistory, setTaskHistory] = useState([]);

    //get individual realtions
    const individualRelations = () => {
        const relOptions = authUser?.individual_only?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get company relations
    const companyRelations = () => {
        const relOptions = authUser?.company_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get ngro relations
    const ngoRelations = () => {
        const relOptions = authUser?.ngo_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get ngro relations
    const mattersRelations = () => {
        const relOptions = authUser?.matter_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    const [activeEditUsers, setActiveEditUsers] = useState('');
    const [activeEditWatchers, setActiveEditWatchers] = useState('');

    //function to add years to a date
    function addYears(date, years) {
        date.setFullYear(date.getFullYear() + years);
        return date;
    }

    //custom select styles
    const customStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '16px',
                color: '#707070 !important',
                border: 'none !important',
                minHeight: "26px",
                maxHeight: "26px",
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                display: "flex",
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#707070",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            minHeight: "26px",
            maxHeight: "26px",
            textTransform: "capitalize",
        }),
        control: (base, provided) => ({
            ...base,
            border: "0 !important",
            boxShadow: 'none !important',
            minHeight: '26px !important',
            height: '26px !important',
            '&:hover': { borderColor: 'transparent', border: 'none' },
            backgroundColor: 'white',
            outline: "none",
            width: "fit-content",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '16px',
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
            maxWidth: '250px',
            marginTop: '0px',
            // boxShadow: '0px 3px 6px rgb(0 0 0 / 16%)',
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            padding: "4px",
            paddingTop: "1px",
        }),
        container: (base) => ({
            ...base,
            height: "26px",
            minHeight: "26px",
            maxHeight: "26px",
        }),
        valueContainer: (base) => ({
            ...base,
            height: "26px",
            minHeight: "26px",
            maxHeight: "26px",
        }),
        input: (base, state) => ({
            ...base,
            backgroundColor: "transparent !important",
            width: 'auto',
            textTransform: "none !important",
            border: "none !important",
            color: "#707070",
            fontSize: "16px",
            height: "23px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    const handleSelectChange = (e, fieldName, setState) => {
        setCurrentInput(fieldName);
        setCurrentInputValue(e.value);
        setTimeout(() => {
            setChangeInput(1)
        }, 100)
        setState(e);
    }

    const handleDatePickerChange = (date, fieldName, setState) => {
        setCurrentInput(fieldName);
        setCurrentInputValue(formatDateTime(date))
        setTimeout(() => {
            setChangeInput(1)
        }, 100)
        setState(date)
    }



    //trigger post to backend once inputs changes
    useEffect(() => {
        if (changeInput > 0 && slug === 'general-info') {
            let fd = new FormData();
            fd.append('target_id', activeTask?.id);
            fd.append('model', 'Task');
            fd.append('relation_field', currentInputValue?.relationField)

            if (currentInputValue?.relationModel) {
                fd.append('relationModel', currentInputValue?.relationModel);
                fd.append('relation_id', currentInputValue?.id);
                if (currentInputValue?.firstInputName) {
                    fd.append('firstInputName', currentInputValue?.firstInputName)
                    fd.append('firstInputValue', currentInputValue?.firstInputValue)
                }
                if (currentInputValue?.secondInputName) {
                    fd.append('secondInputName', currentInputValue?.secondInputName)
                    fd.append('secondInputValue', currentInputValue?.secondInputValue)
                }
            } else {

                fd.append('current_field', currentInput);
                fd.append('current_field_value', currentInputValue);
            }

            if (currentInputValidations) {
                fd.append('validations', currentInputValidations)
            }


            setGeneralDataApiOptions({
                url: 'edit-task-input-data',
                method: 'post',
                data: fd,
                callback: res => {
                    setErrorsReturned({});
                    setCurrentInputValue('');
                    setCurrentInput('');
                    setErrorsReturned({});
                    setChangeInput(0);
                    setActiveTask(res?.data?.task)

                    let formattedHistory = []
                    res?.data?.task?.history?.map(log => {
                        if (Object?.keys(JSON?.parse(log?.new_values)).length > 0 || log?.auditable_type === 'App/TaskAssignee') {
                            if (Object?.keys(JSON?.parse(log?.old_values)).length > 1 && log?.event !== 'created') {
                                Object?.keys(JSON?.parse(log?.old_values)).map((key, i) => {
                                    formattedHistory?.push({
                                        ...log,
                                        new_values: JSON.stringify({ [key]: Object?.values(JSON?.parse(log?.new_values))[i] }),
                                        old_values: JSON.stringify({ [key]: Object?.values(JSON?.parse(log?.old_values))[i] })

                                    })
                                    return null;
                                })
                            } else {
                                formattedHistory?.push(log)
                            }
                        }
                        return null;
                        // Object?.values(JSON?.parse(log?.old_values))
                        // Object?.keys(JSON?.parse(log?.old_values))?.map((val, i) => {
                        //     logger[i][val] = Object?.values(JSON.parse(log?.old_values))[i]
                        // })
                    })
                    setTaskHistory(formattedHistory)
                },
                catch: err => {
                    if (err) {
                        setErrorsReturned(err);
                        setChangeInput(0);
                    }
                }
            })
        }


    }, [changeInput]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCurrentInputValue({})

        setGeneralDataApiOptions({
            url: 'directories-list',
            method: 'post',
            data: {
                user_id: activeMatter?.id,
                all: true,
                noEmptyComment: true,
                orderByDate: true,
                main_directory: 3
            },
            callback: res => {
                setReturnedFiles(res.data?.files);
            },
            catch: err => {
                if (err) {
                }
            }
        })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectedTaskType(getOptions(generalData?.tasksTypes)[getIndex(activeTask?.task_type_id, getOptions(generalData?.tasksTypes), 'value')])
        setDueDate(new Date(activeTask?.date.replace(/-/g, "/")))

        setSelectedRelation(
            Number(activeTask?.client?.client_type_id) === 1 ?
                companyRelations()[getIndex('client-' + activeTask?.client?.id, companyRelations(), 'value')]
                :
                Number(activeTask?.client?.client_type_id) === 2 ?
                    individualRelations()[getIndex('client-' + activeTask?.client?.id, individualRelations(), 'value')]
                    :
                    Number(activeTask?.client?.client_type_id) === 3 ?
                        ngoRelations()[getIndex('client-' + activeTask?.client?.id, ngoRelations(), 'value')]
                        :
                        activeTask?.litigation_id ?
                            mattersRelations()[getIndex(`litigation-${activeTask?.litigation_id}`, mattersRelations(), 'value')]
                            :

                            ''
        )
        let formattedHistory = []
        activeTask?.history?.map(log => {
            if (Object?.keys(JSON?.parse(log?.new_values)).length > 0 || log?.auditable_type === 'App/TaskAssignee') {
                if (Object?.keys(JSON?.parse(log?.old_values)).length > 1 && log?.event !== 'created') {
                    Object?.keys(JSON?.parse(log?.old_values)).map((key, i) => {
                        formattedHistory?.push({
                            ...log,
                            new_values: JSON.stringify({ [key]: Object?.values(JSON?.parse(log?.new_values))[i] }),
                            old_values: JSON.stringify({ [key]: Object?.values(JSON?.parse(log?.old_values))[i] })

                        })
                        return null;
                    })
                } else {
                    formattedHistory?.push(log)
                }
            }
            return null;
            // Object?.values(JSON?.parse(log?.old_values))
            // Object?.keys(JSON?.parse(log?.old_values))?.map((val, i) => {
            //     logger[i][val] = Object?.values(JSON.parse(log?.old_values))[i]
            // })
        })
        setTaskHistory(formattedHistory)
    }, [activeTask]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleClientChange = (client) => {
        setCurrentInput('client_id');
        setCurrentInputValue(client?.value?.split('-')[1])
        setChangeInput(!changeInput)
        setSelectedRelation(client)
    }

    const updateTask = (res, from) => {
        let formattedHistory = [];
        let data = [];
        if(from === 'users-select'){
            data = res?.data;
        }else{
            data = res?.data?.data;
        }
        data?.history?.map(log => {
            if (Object?.keys(JSON?.parse(log?.new_values)).length > 0 || log?.auditable_type === 'App/TaskAssignee') {
                if (Object?.keys(JSON?.parse(log?.old_values)).length > 1 && log?.event !== 'created') {
                    Object?.keys(JSON?.parse(log?.old_values)).map((key, i) => {
                        formattedHistory?.push({
                            ...log,
                            new_values: JSON.stringify({ [key]: Object?.values(JSON?.parse(log?.new_values))[i] }),
                            old_values: JSON.stringify({ [key]: Object?.values(JSON?.parse(log?.old_values))[i] })

                        })
                        return null;
                    })
                } else {
                    formattedHistory?.push(log)
                }
            }
            return null;
            // Object?.values(JSON?.parse(log?.old_values))
            // Object?.keys(JSON?.parse(log?.old_values))?.map((val, i) => {
            //     logger[i][val] = Object?.values(JSON.parse(log?.old_values))[i]
            // })
        })
        setTaskHistory(formattedHistory)
        setActiveTask(data)

        // setGeneralDataApiOptions({
        //     url: 'get-task-data',
        //     method: 'post',
        //     showLoader:false,
        //     data: {
        //         id: Number(activeTask?.id),
        //     },
        //     callback: res => {
        //         setActiveTask(res?.data?.task);
        //     },
        //     catch: err => {
        //         if (err) {
        //             // history('/not-found');
        //         }
        //     }
        // })
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row client-profile-height">
                    <div className="col-12 col-lg-4 pt-4 drop-shadow-div scrollable-client-profile-height">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center pb-3">
                                <div className="position-relative">
                                    <img className="img-fluid" src={'/assets/images/task-profile.png'} alt="profile" />
                                </div>
                            </div>


                            {/* status */}
                            <div className="col-auto">
                                <StatusComponent
                                    api={'edit-task-status'}
                                    selectedStatus={activeTask?.status}
                                    statuses={tasksStatuses}
                                    target={activeTask.id}
                                    returnSingleTask={1}
                                    targetName={'task_id'}
                                    dropdownPosition={'start-0'}
                                    onChange={(data) => {
                                        updateTask({ data: data });
                                    }}
                                    editable={true}
                                />
                            </div>


                            {/* priority  */}
                            <div className="col-auto  ms-auto">
                                <PriorityComponent
                                    selectedPriority={activeTask?.priority}
                                    priorities={tasksPriorities}
                                    api={'edit-task-priority'}
                                    returnSingleTask={1}
                                    target={activeTask.id}
                                    targetName={'task_id'}
                                    onChange={(data) => { updateTask({ data: data }) }}
                                    editable={true}
                                />

                            </div>
                            <div className="col-12 my-auto align-items-center pb-4">
                                <div className="row">

                                    {/* title */}
                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/LitigationTitleIcon.svg" alt="name" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['name']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                <EditInput
                                                    placeholder={generalData?.website_titles['name']}
                                                    withPadd={1}
                                                    inputType={'text'}
                                                    postUrl="edit-litigation-data"
                                                    data={activeTask}
                                                    value={activeTask?.name ? activeTask?.name : ''}
                                                    inputName={"name"}
                                                    userId={user_id}
                                                    validation={'required'}
                                                    errorsReturned={errorsReturned}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    {/* client */}
                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/LitigationTitleIcon.svg" alt="name" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['name']}</p>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-6 pt-1 px-0 ">
                                                <div className=" ps-4">
                                                    <RelationsSelect
                                                        selectedRelation={selectedRelation}
                                                        setSelectedRelation={handleClientChange}
                                                        profile={authUser}
                                                        excludeTasks
                                                        editInput
                                                        // placeholder={selectedShareOperation && selectedShareOperation.slug !== 'initial-subscription' && generalData?.shareholdersFixedTitles['to']}
                                                        defaultSelected={clientType ? (clientType === 1 ? 'company' : (clientType === 2 ? 'individual' : 'ngo')) : 'individual'}
                                                        isDisabled={activeTask?.user_id !== authUser?.id && authUser?.user_role_id !== 1}
                                                    />
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    {/* number */}
                                    <div className="col-12 col-sm-6 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/LitigationNumberIcon.svg" alt="name" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['number']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                {/* <EditInput
                                                    placeholder={generalData?.matterSectionsSettings['litigation-number']}
                                                    withPadd={1}
                                                    inputType={'text'}
                                                    inputName={"number"}
                                                    postUrl="edit-client-data"
                                                    data={activeMatter}
                                                    value={formData?.number ? formData?.number : ''}
                                                    userId={user_id}
                                                    validation={'required'}
                                                    errorsReturned={errorsReturned}
                                                /> */}
                                                <p className="ps-4 mb-0">TA{activeTask?.id}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* type  */}
                                    <div className="col-12 col-sm-6 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/LitigationTypeIcon.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.tasksFixedTitles['task-type']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-8 pt-1">
                                                {/* <div style={{ zIndex: "999" }} className="col-auto" onClick={(e) => addToFormData('nationalities')}>
                                                    </div> */}
                                                <Select
                                                    isDisabled={activeTask?.user_id !== authUser?.id && authUser?.user_role_id !== 1}
                                                    isSearchable={true}
                                                    classNamePrefix="profile-select"
                                                    className="profile-select w-100 my-auto ps-4"
                                                    styles={customStyles}
                                                    options={getOptions(generalData?.tasksTypes, 'slug', 'id', 'title')}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    placeholder={generalData?.tasksFixedTitles['task-type']}
                                                    value={selectedTaskType ? selectedTaskType : null}
                                                    onChange={(e) => { handleSelectChange(e, 'task_type_id', setSelectedTaskType) }}
                                                />
                                            </div>
                                            {
                                                (errorsReturned && errorsReturned?.nationalities) || (errorsReturned && errorsReturned?.nationality_id) ?
                                                    <>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.nationalities}</p>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.nationality_id}</p>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                    {/* due date */}
                                    <div className="col-12 col-md-6 pt-3">
                                        <div className="col-12 d-flex">
                                            <img className="profile-general-svg me-2" src="/assets/images/LitigationTypeIcon.svg" alt="date" />
                                            <p className="profile-general-input-title">{generalData?.tasksFixedTitles['due-date']}</p>
                                        </div>
                                        <div className="col-12 col-lg-10 col-xl-3  position-relative with-time-select">
                                            <DatePicker
                                                className="birthdate-profile ps-4 "
                                                placeholderText={generalData?.tasksFixedTitles['due-date']}
                                                value={dueDate ? dueDate : ''}
                                                showMonthDropdown
                                                showYearDropdown
                                                showTimeSelect
                                                dropdownMode="select"
                                                selected={dueDate}
                                                use12Hours={true}
                                                dateFormat="d MMMM yyyy hh:mm a"
                                                onChange={(date) => { handleDatePickerChange(date, 'date', setDueDate) }}
                                                maxDate={addYears(new Date(), +10)}
                                                minDate={addYears(new Date(), -10)}
                                                disabled={activeTask?.user_id !== authUser?.id && authUser?.user_role_id !== 1}
                                                locale={localStorage.getItem("BlpLanguage")}
                                            />

                                        </div>
                                    </div>

                                    {/* assignees  */}
                                    <div>
                                        <div className="row py-3">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/LitigationPersonIcon.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.tasksFixedTitles['assignees']}</p>
                                            </div>
                                            <div className="col-12" >
                                                <div className="w-100 h-100 d-block  ps-4 pt-1" style={{ zIndex: 999 }}>
                                                    <UsersSelect
                                                        url={'/add-edit-task-assignees'}
                                                        activeEditUsers={activeEditUsers}
                                                        setActiveEditUsers={setActiveEditUsers}
                                                        data={activeTask}
                                                        model={'Task'}
                                                        users={activeTask?.assignees}
                                                        clientId={''}
                                                        returnSingleTask={1}
                                                        className="litigation-user-select m-0"
                                                        onChange={(data) => { updateTask({ data: data }, 'users-select') }}
                                                        editable={activeTask?.user_id === authUser?.id || authUser?.user_role_id === 1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* created by  */}
                                    <div>
                                        <div className="row py-3">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/LitigationPersonIcon.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.tasksFixedTitles['created-by']}</p>
                                            </div>
                                            <div className="col-12" >
                                                <div className="w-100 h-100 d-block  ps-4 pt-1" style={{ zIndex: 999 }}>
                                                    <UsersSelect
                                                        url={'/add-edit-assignees'}
                                                        activeEditUsers={activeEditUsers}
                                                        setActiveEditUsers={setActiveEditUsers}
                                                        data={activeTask}
                                                        model={'Task'}
                                                        users={[activeTask?.user]}
                                                        clientId={''}
                                                        editable={false}
                                                        className="litigation-user-select m-0"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* watchers */}
                                    <div>
                                        <div className="row py-3">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/LitigationPersonIcon.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.tasksFixedTitles['watchers']}</p>
                                            </div>
                                            <div className="col-12" >
                                                <div className="w-100 h-100 d-block  ps-4 pt-1" style={{ zIndex: 999 }}>
                                                    <UsersSelect
                                                        url={'/add-edit-assignees'}
                                                        activeEditUsers={activeEditWatchers}
                                                        setActiveEditUsers={setActiveEditWatchers}
                                                        data={activeTask}
                                                        model={'Task'}
                                                        fieldName={'watchers'}
                                                        users={activeTask?.watchers}
                                                        clientId={''}
                                                        returnSingleTask={1}
                                                        className="litigation-user-select m-0"
                                                        editable={activeTask?.user_id === authUser?.id || authUser?.user_role_id === 1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* additional_information */}
                    <div className="col-12 col-lg-8 pt-4 px-lg-4 pb-5 scrollable-client-profile-height">
                        <div className="row py-3">
                            <h1 className="profile-big-title">
                                {generalData?.litigationFixedTitles['description']}
                            </h1>
                            <div className="col-12">
                                <div className="row g-0 p-0 py-4 costs-box-styling custom-shadow px-2">
                                    <div className="col-12" >
                                        <EditInput
                                            inputType={'text-area'}
                                            placeholder={generalData?.litigationFixedTitles['description']}
                                            withPadd={1}
                                            data={activeTask}
                                            value={activeTask?.note ? activeTask?.note : ''}
                                            inputName={"note"}
                                            userId={user_id}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* <DocumentsSmallTab userId={user_id} files={profileClient?.home_files} /> */}

                        <ClientComments
                            commentsData={activeTask?.comments}
                            api={'add-edit-task-comment'}
                            deleteApi={'delete-task-comment'}
                            relation={'task_id'}
                            relation_id={activeTask?.id}
                            section={'general-info'}
                            setState={(data) => setActiveTask(data?.task)}
                        />

                        <div className="row py-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h1 className="profile-big-title">
                                    {generalData?.tasksFixedTitles['tasks-history']}
                                </h1>
                                {
                                    taskHistory?.length > 5 &&
                                    <a href={`/tasks/${activeTask?.id}/history`}>{generalData?.fixed_titles['view-all']}</a>
                                }

                            </div>

                            {
                                taskHistory &&
                                <TaskHistoryComponent showCount={5} taskHistory={taskHistory} />
                                // <LogsComponent showCount={returnedFiles?.length} returnedFiles={returnedFiles} generalData={generalData} />
                            }

                        </div>
                    </div>
                </div>
            </div>


            <Api options={usersApiOptions} />
            <Api options={generalDataApiOptions} />
        </>
    )
}