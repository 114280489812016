import 'react-calendar/dist/Calendar.css';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import GlobalState from '../contexts/GlobalState';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Api from './Api';
import { addYears, getIndex, formatDate } from '../Helpers';
import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import RelationsSelect from './RelationsSelect';
import { CloseIcon, ThreeDotsMenu } from './Icons';

registerLocale('ar', ar)

export default function AdministrativeBody(props) {

    const { authUserGlobal, profileClientGlobal, generalDataGlobal } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [authUser] = authUserGlobal;
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [generalData,] = generalDataGlobal;
    const [errorsReturned, setErrorsReturned] = useState({});
    const [administrativePopup, setAdministrativePopup] = useState(false);
    const [currentMember, setCurrentMember] = useState(null);
    const [comment, setComment] = useState('');
    const [editRelationMenu, setEditRelationMenu] = useState(null);
    const [deletePopup, setDeletePopup] = useState(null);

    const [clientRole, setClientRole] = useState('');
    const [designationDate, setDesignationDate] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [memberStatus, setMemberStatus] = useState('');
    const [assembly, setAssembly] = useState(false);

    //all client statuses from backend
    const clientStatuses = () => {
        const clientStat = generalData?.client_statuses?.map(
            stat => ({ slug: stat?.slug, value: stat.id, label: stat.title }))
        return clientStat;
    }

    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#00366A",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            minWidth: '40px !important'
        })
    };

    //to reset all states
    const closeAddBoard = (e) => {
        setAdministrativePopup(false);
        setErrorsReturned({});
        setCurrentMember(null);
        setClientRole('');
        setMemberStatus('');
        setComment('');
        setSelectedRelation('');
        setDesignationDate('');
        setExpiryDate('');
        setEditRelationMenu(null);
        setDeletePopup(null);
        setAssembly(false);
    }

    //cancel or close delete popup and reset states
    const cancelDeletePopup = (e) => {
        setDeletePopup(null);
        setEditRelationMenu(null);
        closeAddBoard(e);
    }


    // when editing, this will set the states with the clicked element data
    const editRelationClick = (e, data) => {
        setComment(data?.comment ? data?.comment : '');
        setClientRole(clientRoles()[getIndex(data?.client_roles_id, clientRoles(), 'value')]);
        if (data?.general_assembly === 1) {
            setAssembly(true);
        } else {
            setAssembly(false);
        }

        // to set client type in relation name
        if (data?.administrative?.client_type?.slug) {
            setCustomRelationFilter(data?.administrative?.client_type?.slug);
        }
        // to set client type in relation name
        if (data?.administrative?.client_type_id === 1) {
            let text = 'client-' + data?.administrative_id;
            setTimeout(() => {
                setSelectedRelation(companyRelations()[getIndex(text, companyRelations(), 'value')]);
            }, 100);
        }
        // to set client type in relation name
        if (data?.administrative?.client_type_id === 2) {
            let text = 'client-' + data?.administrative_id;
            setTimeout(() => {
                setSelectedRelation(individualRelations()[getIndex(text, individualRelations(), 'value')]);
            }, 100);
        }
        // to set client type in relation name
        if (data?.administrative?.client_type_id === 3) {
            let text = 'client-' + data?.administrative_id;
            setTimeout(() => {
                setSelectedRelation(ngoRelations()[getIndex(text, ngoRelations(), 'value')]);
            }, 100);
        }
        setCurrentMember(data);
        setDesignationDate(data?.designation_date ? new Date(data?.designation_date) : '');
        setExpiryDate(data?.expiry_date ? new Date(data?.expiry_date) : '');
        setMemberStatus(clientStatuses()[getIndex(data?.client_stat_id, clientStatuses(), 'value')]);
        setTimeout(() => {
            setAdministrativePopup(true);
        }, 200);

    }


    // once delete is confirmed for a member
    const deleteElement = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        fd.append('user_id', props.user_id);
        fd.append('client_id', props.user_id);

        if (deletePopup && deletePopup?.id) {
            fd.append('member_id', deletePopup?.id);
        }

        setGeneralDataApiOptions({
            url: 'delete-administrative',
            method: 'post',
            data: fd,
            callback: res => {
                closeAddBoard(e);
                setProfileClient(res.data);
            },
            catch: err => {
                setErrorsReturned(err);
            }
        });
    }

    //add edit members post to backend
    const submitMember = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        fd.append('administrative_id', selectedRelation ? selectedRelation?.value : '');
        fd.append('user_id', props.user_id);
        fd.append('client_id', props.user_id);
        fd.append('comment', comment ? comment : '');
        fd.append('client_roles_id', clientRole ? clientRole?.value : '');
        fd.append('designation_date', designationDate ? formatDate(designationDate) : '');
        fd.append('expiry_date', expiryDate ? formatDate(expiryDate) : '');
        fd.append('client_stat_id', memberStatus ? memberStatus?.value : '');
        fd.append('general_assembly', assembly ? 1 : 0);

        fd.append('description', comment);
        fd.append('return_row', 1);
        fd.append('relation_name', selectedRelation ? selectedRelation?.value : '');

        if (currentMember && currentMember?.id) {
            fd.append('member_id', currentMember?.id);
            if (currentMember?.client_relations_id) {
                fd.append('relation_id', currentMember?.client_relations_id);
            }
        }

        setGeneralDataApiOptions({
            url: 'add-edit-administrative',
            method: 'post',
            data: fd,
            callback: res => {
                closeAddBoard(e);
                setProfileClient(res.data);
            },
            catch: err => {
                setErrorsReturned(err);
            }
        });
    }


    // get all individual from generaldata
    const individualRelations = () => {
        const relOptions = profileClient?.individual_only?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    // get all companies from generaldata
    const companyRelations = () => {
        const relOptions = profileClient?.company_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    // get all ngos from generaldata
    const ngoRelations = () => {
        const relOptions = profileClient?.ngo_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    // get all client roles from general data
    const clientRoles = () => {
        const clientRole = generalData?.client_roles?.map(
            singleRole => ({ slug: singleRole?.slug, value: singleRole.id, label: singleRole.title }))
        return clientRole;
    }

    const [customRelationFilter, setCustomRelationFilter] = useState('individual');
    const [selectedRelation, setSelectedRelation] = useState('');

    useEffect(() => {
        setSelectedRelation('');
    }, [customRelationFilter]);
    /* */

    return (
        <>
            {
                // validate that profile client is valid and id same as the props.user_id
                profileClient && profileClient.id === Number(props.user_id) ?
                    <>
                        <div className="container-fluid documents-div pt-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col d-flex flex-row">
                                            <div className="col-auto my-auto d-flex">
                                                <h1 className="m-0 d-flex">{generalData?.website_titles['administrative-body']}
                                                    {
                                                        authUser?.user_role_id <= 2 ?
                                                            <img style={{ height: "30px" }} className="cursor-pointer" src={'/assets/images/plus.svg'} onClick={(e) => setAdministrativePopup(true)} alt="add" />
                                                            :
                                                            null
                                                    }
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-2">
                                {
                                    //when administrative_body lenght > 0 we should map them else we show the no admin...
                                    profileClient?.administrative_body?.length > 0 ?
                                        profileClient?.administrative_body.map((singleMember, memberIndex) => (
                                            <div key={memberIndex} className="col-12 col-md-6 py-2 h-auto">
                                                <div className="cms-table-content position-relative h-100">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="gray-background">
                                                                <div className="row py-2 px-3">
                                                                    <div className="col-auto">
                                                                        <img className="image-user-chat" src={singleMember?.administrative?.picture ? singleMember?.administrative?.full_path_picture : (singleMember?.administrative?.client_type_id === 1 ? generalData?.fixed_settings?.full_path_no_company_picture : (singleMember?.administrative?.client_type_id === 2 ? generalData?.fixed_settings?.full_path_no_individual_picture : generalData?.fixed_settings?.full_path_no_ngo_picture))} alt="chat user" />
                                                                    </div>
                                                                    <div className="col ps-0">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-auto">
                                                                                <p className="m-0 font-semi-bold">{singleMember?.administrative?.name}</p>
                                                                                <h5 className="locked-inputs w-100 fs-14">{singleMember?.administrative?.client_type?.title}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        authUser?.user_role_id <= 2 ?
                                                                            <div className="col-auto pe-0 d-flex align-items-center cursor-pointer">
                                                                                <div className="text-end p-0 position-relative" >
                                                                                    <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); editRelationMenu?.id === singleMember?.id ? setEditRelationMenu(null) : setEditRelationMenu(singleMember) }} />
                                                                                    <div style={{ top: '0px' }} className={"settings-clickmenu text-start " + (singleMember?.id === editRelationMenu?.id ? ' d-block' : '')}>
                                                                                        <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editRelationClick(e, singleMember) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                        <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeletePopup(singleMember) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row px-3 py-2">
                                                        {
                                                            singleMember?.designation_date ?
                                                                <div className="col-12 col-md-6 py-1">
                                                                    <p className="profile-general-input-title">{generalData?.website_titles['designation-date']}</p>
                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                        {singleMember?.designation_date}
                                                                    </h5>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            singleMember?.expiry_date ?
                                                                <div className="col-12 col-md-6 py-1">
                                                                    <p className="profile-general-input-title">{generalData?.website_titles['expiry-date']}</p>
                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                        {singleMember?.expiry_date}
                                                                    </h5>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            singleMember?.client_roles_id ?
                                                                <div className="col-12 col-md-6 py-1">
                                                                    <p className="profile-general-input-title">{generalData?.website_titles['role']}</p>
                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                        {singleMember?.client_roles?.title}
                                                                    </h5>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            singleMember?.client_stat_id ?
                                                                <div className="col-12 col-md-6 py-1">
                                                                    <p className="profile-general-input-title">{generalData?.website_titles['status']}</p>
                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                        {singleMember?.client_stat?.title}
                                                                    </h5>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            singleMember?.comment ?
                                                                <div className="col-12 py-1">
                                                                    <p className="profile-general-input-title">{generalData?.website_titles['comment']}</p>
                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                        {singleMember?.comment}
                                                                    </h5>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div className="col-12">
                                            <h5 className="locked-inputs w-100 m-0 p-0">{generalData?.website_titles['no-administrative-body-members-yet']}</h5>
                                        </div>
                                }
                            </div>

                            <div className="row pt-4">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col d-flex flex-row">
                                            <div className="col-auto my-auto d-flex">
                                                <h1 className="m-0 d-flex">{generalData?.website_titles['general-assembly']}
                                                    <img style={{ height: "30px" }} className="cursor-pointer" src={'/assets/images/plus.svg'} onClick={(e) => { setAdministrativePopup(true); setAssembly(true) }} alt="add" />
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-2">
                                {
                                    profileClient?.general_assembly?.length > 0 ?
                                        profileClient?.general_assembly.map((singleMember, memberIndex) => (
                                            <div key={memberIndex} className="col-12 col-md-6 col-lg-4 py-2 h-auto">
                                                <div className="cms-table-content position-relative h-100">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="gray-background">
                                                                <div className="row py-2 px-3">
                                                                    <div className="col-auto">
                                                                        <img className="image-user-chat" src={singleMember?.administrative?.picture ? singleMember?.administrative?.full_path_picture : (singleMember?.administrative?.client_type_id === 1 ? generalData?.fixed_settings?.full_path_no_company_picture : (singleMember?.administrative?.client_type_id === 2 ? generalData?.fixed_settings?.full_path_no_individual_picture : generalData?.fixed_settings?.full_path_no_ngo_picture))} alt="chat user" />
                                                                    </div>
                                                                    <div className="col ps-0">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-auto">
                                                                                <p className="m-0 font-semi-bold">{singleMember?.administrative?.name}</p>
                                                                                <h5 className="locked-inputs w-100 fs-14">{singleMember?.administrative?.client_type?.title}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        authUser?.user_role_id <= 2 ?
                                                                            <div className="col-auto pe-0 d-flex align-items-center cursor-pointer">
                                                                                <div className="text-end p-0 position-relative" >
                                                                                    <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); editRelationMenu?.id === singleMember?.id ? setEditRelationMenu(null) : setEditRelationMenu(singleMember) }} />
                                                                                    <div style={{ top: '0px' }} className={"settings-clickmenu text-start " + (singleMember?.id === editRelationMenu?.id ? ' d-block' : '')}>
                                                                                        <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editRelationClick(e, singleMember) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                        <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeletePopup(singleMember) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row px-3 py-2">
                                                        {
                                                            singleMember?.client_stat_id ?
                                                                <div className="col-12 col-md-6 py-1">
                                                                    <p className="profile-general-input-title">{generalData?.website_titles['status']}</p>
                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                        {singleMember?.client_stat?.title}
                                                                    </h5>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div className="col-12">
                                            <h5 className="locked-inputs w-100 m-0 p-0">{generalData?.website_titles['no-general-assembly-members']}</h5>
                                        </div>
                                }
                            </div>
                        </div>
                        {
                            //Add administrative Body member popup 
                            administrativePopup ?
                                <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                    <div className="pop-up-api-box">
                                        <CloseIcon className="cursor-pointer" onClick={(e) => closeAddBoard(e)} />
                                        <div className="text-center ">
                                            <div className="containter-fluid">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currentMember?.id ? generalData?.website_titles['edit-board-member'] : generalData?.website_titles['add-board-member']}</h1>
                                                    </div>
                                                    <form onSubmit={submitMember}>
                                                        <div className="col-12 pt-2 pb-4">
                                                            <div className="row">
                                                                <div className="col-6 col-md-6 pt-2">
                                                                    <div className="position-relative text-start justify-content-start w-100">
                                                                        <RelationsSelect
                                                                            selectedRelation={selectedRelation}
                                                                            value={selectedRelation}
                                                                            setSelectedRelation={setSelectedRelation}
                                                                            profile={profileClient}
                                                                            defaultSelected={'individual'}
                                                                            excludeMatters
                                                                            excludeTasks
                                                                            className="add-user-inputs"
                                                                        />
                                                                        {
                                                                            errorsReturned?.administrative_id ?
                                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.administrative_id}</h5>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    !assembly ?
                                                                        <>
                                                                            <div className="col-12 col-md-6 pt-2">
                                                                                <div className="postion-relative text-start justify-content-start w-100">
                                                                                    <DatePicker
                                                                                        className="popup-date add-user-inputs"
                                                                                        placeholderText={generalData?.website_titles['designation-date']}
                                                                                        value={designationDate ? designationDate : ''}
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        selected={designationDate}
                                                                                        dateFormat="d MMMM yyyy"
                                                                                        onChange={(date) => setDesignationDate(date)}
                                                                                        minDate={addYears(new Date(), -50)}
                                                                                        locale={localStorage.getItem("BlpLanguage")}
                                                                                        maxDate={addYears(new Date(), +10)}
                                                                                    />

                                                                                </div>
                                                                                {
                                                                                    errorsReturned?.designation_date ?
                                                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.designation_date}</h5>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>
                                                                            <div className="col-12 col-md-6 pt-2">
                                                                                <div className="postion-relative text-start justify-content-start w-100">
                                                                                    <DatePicker
                                                                                        className="popup-date add-user-inputs"
                                                                                        placeholderText={generalData?.website_titles['expiry-date']}
                                                                                        value={expiryDate ? expiryDate : ''}
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        selected={expiryDate}
                                                                                        dateFormat="d MMMM yyyy"
                                                                                        onChange={(date) => setExpiryDate(date)}
                                                                                        locale={localStorage.getItem("BlpLanguage")}
                                                                                        minDate={addYears(new Date(), -10)}
                                                                                        maxDate={addYears(new Date(), +100)}
                                                                                    />
                                                                                </div>
                                                                                {
                                                                                    errorsReturned?.expiry_date ?
                                                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.expiry_date}</h5>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>
                                                                            <div className="col-12 col-md-6 pt-2">
                                                                                <div className="postion-relative text-start justify-content-start w-100">
                                                                                    <Select
                                                                                        classNamePrefix="popup-select"
                                                                                        className="popup-select w-100"
                                                                                        styles={customStylesPopup}
                                                                                        options={clientRoles()}
                                                                                        components={{
                                                                                            IndicatorSeparator: () => null
                                                                                        }}
                                                                                        placeholder={generalData?.website_titles['role']}
                                                                                        isSearchable={false}
                                                                                        value={clientRole ? clientRole : null}
                                                                                        onChange={setClientRole} />
                                                                                </div>
                                                                                {
                                                                                    errorsReturned?.client_roles_id ?
                                                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.client_roles_id}</h5>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                <div className="col-12 col-md-6 pt-2">
                                                                    <div className="postion-relative text-start justify-content-start w-100">
                                                                        <Select
                                                                            classNamePrefix="popup-select"
                                                                            className="popup-select w-100"
                                                                            styles={customStylesPopup}
                                                                            options={clientStatuses()}
                                                                            components={{
                                                                                IndicatorSeparator: () => null
                                                                            }}
                                                                            placeholder={generalData?.website_titles['status']}
                                                                            isSearchable={false}
                                                                            value={memberStatus ? memberStatus : null}
                                                                            onChange={setMemberStatus} />
                                                                    </div>
                                                                    {
                                                                        errorsReturned?.client_stat_id ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.client_stat_id}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                                {
                                                                    !assembly ?
                                                                        <div className="col-12 pt-2">
                                                                            <textarea type="text-area" name="description" className="add-user-inputs" placeholder={generalData?.website_titles['comment']} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                                                            {
                                                                                errorsReturned?.comment ?
                                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {
                                                                                errorsReturned?.board_exists ?
                                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.board_exists}</h5>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-12 d-flex justify-content-center">
                                                            <button type="submit" className="popup-button my-auto">{currentMember?.id ? generalData?.website_titles['edit'] : generalData?.website_titles['add']}</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }

                        {
                            // once delete is pressed we show the popup
                            deletePopup ?
                                <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                    <div className="pop-up-api-box">
                                        <CloseIcon className="cursor-pointer" onClick={(e) => cancelDeletePopup(e)} />
                                        <div className="text-center ">
                                            <div className="containter-fluid">
                                                <div className="row d-flex justify-content-center align-items-center">
                                                    <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                        <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                        <button onClick={(e) => deleteElement(e)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                        <button onClick={(e) => cancelDeletePopup(e)} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }

                        {/* API call using the API component */}
                        <Api options={generalDataApiOptions} />
                    </>
                    :
                    null
            }
        </>
    )
}