import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { useNavigate, useParams } from "react-router";
import React from 'react';
import Api from "./Api";
import EditInput from "./EditInput";
import UpdatesEditInput from "./UpdatesEditInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import 'swiper/css';
import ClientComments from "./ClientComments";


import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { getOptions, getIndex, formatDate } from "../Helpers";
import UsersSelect from "./UsersSelect";
import { AddIconLt, CloseIcon, RemoveFd } from "./Icons";
import StatusComponent from "./StatusComponent";
import PriorityComponent from "./PriorityComponent";
import LogsComponent from "./LogsComponent";

registerLocale('ar', ar)

export default function LitigationGeneralInfo(props) {

    const {
        authUserGlobal: [authUser],
        generalDataGlobal: [generalData],
        changeInputGlobal,
        currentInputGlobal,
        currentInputValueGlobal,
        currentInputValidationsGlobal,
        profileClientGlobal,
        activeMatter, setActiveMatter,
    } = useContext(GlobalState);
    const history = useNavigate();
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    let { slug, user_id ,type, matter_id} = useParams();
    // const [generalData] = generalDataGlobal;
    const [usersApiOptions,] = useState({});
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [currentInput, setCurrentInput] = currentInputGlobal;
    const [currentInputValue, setCurrentInputValue] = currentInputValueGlobal;
    const [currentInputValidations,] = currentInputValidationsGlobal;
    const { statuses, priorities } = generalData;
    const [changeInput, setChangeInput] = changeInputGlobal;

    const [errorsReturned, setErrorsReturned] = useState({});
    const [formData, setFormData] = useState({});
    const [, setJobTitle] = useState();
    const [deletePopup, setDeletePopup] = useState(null);

    const [selectedLitigationType, setSelectedLitigationType] = useState();
    const [, setSelectedLitigationStage] = useState();
    const [establishDate, setEstablishDate] = useState();
    const [appealDate, setAppealDate] = useState();
    const [roundDate, setRoundDate] = useState();
    const [excludeValues, setExcludeValues] = useState([]);

    const [returnedFiles, setReturnedFiles] = useState({});

    //get individual realtions
    const individualRelations = () => {
        const relOptions = authUser?.individual_only?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get company relations
    const companyRelations = () => {
        const relOptions = authUser?.company_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get ngro relations
    const ngoRelations = () => {
        const relOptions = authUser?.ngo_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    const [, setZoom] = useState(1);
    const [activeEditUsers, setActiveEditUsers] = useState('');

    //function to add years to a date
    function addYears(date, years) {
        date.setFullYear(date.getFullYear() + years);
        return date;
    }

    //custom select styles
    const customStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '16px',
                color: '#707070 !important',
                border: 'none !important',
                minHeight: "26px",
                maxHeight: "26px",
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                display: "flex",
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#707070",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            minHeight: "26px",
            maxHeight: "26px",
            textTransform: "capitalize",
        }),
        control: (base, provided) => ({
            ...base,
            border: "0 !important",
            boxShadow: 'none !important',
            minHeight: '26px !important',
            height: '26px !important',
            '&:hover': { borderColor: 'transparent', border: 'none' },
            backgroundColor: 'white',
            outline: "none",
            width: "fit-content",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '16px',
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
            maxWidth: '250px',
            marginTop: '0px',
            boxShadow: '0px 3px 6px rgb(0 0 0 / 16%)',
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            padding: "4px",
            paddingTop: "1px",
        }),
        container: (base) => ({
            ...base,
            height: "26px",
            minHeight: "26px",
            maxHeight: "26px",
        }),
        valueContainer: (base) => ({
            ...base,
            height: "26px",
            minHeight: "26px",
            maxHeight: "26px",
        }),
        input: (base, state) => ({
            ...base,
            backgroundColor: "transparent !important",
            width: 'auto',
            textTransform: "none !important",
            border: "none !important",
            color: "#707070",
            fontSize: "16px",
            height: "23px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    const handleSelectChange = (e, fieldName, setState) => {
        setCurrentInput(fieldName);
        setCurrentInputValue(e.value);
        setTimeout(() => {
            setChangeInput(1)
        }, 100)
        setState(e);
    }

    const handleDatePickerChange = (date, fieldName, setState) => {
        setCurrentInput(fieldName);
        setCurrentInputValue(formatDate(date))
        setTimeout(() => {
            setChangeInput(1)
        }, 100)
        setState(date)
    }

    //set page states with curren profile data when profile client changes or user_id
    useEffect(() => {
        setFormData(activeMatter);
        setEstablishDate(activeMatter?.establish_date ? new Date(activeMatter?.establish_date) : '')
        setAppealDate(activeMatter?.appeal_date ? new Date(activeMatter?.appeal_date) : '')
        setRoundDate(activeMatter?.round_date ? new Date(activeMatter?.round_date) : '')
        setJobTitle(getOptions(generalData?.litigationWorkAreas)[getIndex(activeMatter?.work_area_id, getOptions(generalData?.litigationWorkAreas), 'value')]);
        setSelectedLitigationType(getOptions(generalData?.litigationTypes, 'slug', 'id', 'title')[getIndex(activeMatter?.type_id, getOptions(generalData?.litigationTypes, 'slug', 'id', 'title'), 'value')])
        setSelectedLitigationStage(getOptions(generalData?.litigationStages)[getIndex(activeMatter?.stage_id, getOptions(generalData?.litigationStages), 'value')])

        let emptyObject = { ...formData };
        emptyObject['clients'] = activeMatter?.clients?.length > 0
            ?
            activeMatter?.clients.map((client, index) => (
                {
                    id: client.id,
                    selectedClient:
                        Number(client?.client?.client_type_id) === 1 ?
                            companyRelations()[getIndex('client-' + client?.client?.id, companyRelations(), 'value')]
                            :
                            Number(client?.client?.client_type_id) === 2 ?
                                individualRelations()[getIndex('client-' + client?.client?.id, individualRelations(), 'value')]
                                :
                                Number(client?.client?.client_type_id) === 3 ?
                                    ngoRelations()[getIndex('client-' + client?.client?.id, ngoRelations(), 'value')]
                                    :
                                    ''
                    ,
                    description: client.description
                }
            ))
            :
            [{ client: '', description: '' }];

        emptyObject['opponents'] = activeMatter?.opponents?.length > 0
            ?
            activeMatter.opponents.map((opponent) => (
                {
                    id: opponent?.id,
                    selectedClient:
                        Number(opponent?.client?.client_type_id) === 1 ?
                            companyRelations()[getIndex('client-' + opponent?.client?.id, companyRelations(), 'value')]
                            :
                            Number(opponent?.client?.client_type_id) === 2 ?
                                individualRelations()[getIndex('client-' + opponent?.client?.id, individualRelations(), 'value')]
                                :
                                Number(opponent?.client?.client_type_id) === 3 ?
                                    ngoRelations()[getIndex('client-' + opponent?.client?.id, ngoRelations(), 'value')]
                                    :
                                    ''
                    ,
                    description: opponent.description
                }
            )) : [{ client: '', description: '' }];
        emptyObject['parties'] = activeMatter?.parties?.length > 0
            ?
            activeMatter.parties.map((party) => (
                {
                    id: party?.id,
                    selectedClient:
                        Number(party?.party?.client_type_id) === 1 ?
                            companyRelations()[getIndex('client-' + party?.party?.id, companyRelations(), 'value')]
                            :
                            Number(party?.party?.client_type_id) === 2 ?
                                individualRelations()[getIndex('client-' + party?.party?.id, individualRelations(), 'value')]
                                :
                                Number(party?.party?.client_type_id) === 3 ?
                                    ngoRelations()[getIndex('client-' + party?.party?.id, ngoRelations(), 'value')]
                                    :
                                    ''
                    ,
                    description: party.description
                }
            )) : [{ client: '', description: '' }];

        setFormData((prev) => (
            {
                ...prev,
                clients: [
                    ...emptyObject.clients
                ],
                opponents: [
                    ...emptyObject.opponents
                ],
                parties: [
                    ...emptyObject.parties
                ]
            }
        ))

    }, [activeMatter, user_id]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (authUser) {
            setGeneralDataApiOptions({
                url: 'get-matter-data',
                method: 'post',
                data: {
                    id: Number(matter_id),
                    type: type,
                },
                callback: res => {
                    setActiveMatter(res?.data);
                },
                catch: err => {
                    if (err) {
                        history('/not-found');
                    }
                }
            })
        }
    },[authUser]);// eslint-disable-line react-hooks/exhaustive-deps
    //trigger post to backend once inputs changes
    useEffect(() => {
        if (changeInput > 0 && (slug === 'general-info' || !slug)) {
            let fd = new FormData();
            fd.append('target_id', activeMatter?.id);
            fd.append('model', 'Litigation');
            fd.append('relation_field', currentInputValue?.relationField)

            if (currentInputValue?.relationModel) {
                fd.append('relationModel', currentInputValue?.relationModel);
                fd.append('relation_id', currentInputValue?.id);
                if (currentInputValue?.firstInputName) {
                    fd.append('firstInputName', currentInputValue?.firstInputName)
                    fd.append('firstInputValue', currentInputValue?.firstInputValue)
                }
                if (currentInputValue?.secondInputName) {
                    fd.append('secondInputName', currentInputValue?.secondInputName)
                    fd.append('secondInputValue', currentInputValue?.secondInputValue)
                    if(currentInputValue?.thirdInputValue){
                        fd.append('thirdInputValue', currentInputValue?.thirdInputValue)
                        fd.append('folder_name','Matters');
                        fd.append('force', '1');
                        fd.append('main_directory',3);
                        fd.append('file', currentInputValue?.thirdInputValue)
                        if(currentInputValue?.thirdInputValue === 1){
                            fd.append('delete_file',1);
                        }
                    }
                }
            } else {

                fd.append('current_field', currentInput);
                fd.append('current_field_value', currentInputValue);
            }

            if (currentInputValidations) {
                fd.append('validations', currentInputValidations)
            }


            setGeneralDataApiOptions({
                url: 'edit-input-data',
                method: 'post',
                data: fd,
                callback: res => {
                    setErrorsReturned({});
                    setActiveMatter(res?.data);
                    setProfileClient(res.data);
                    setCurrentInputValue('');
                    setCurrentInput('');
                    setErrorsReturned({});
                    setDeletePopup();
                    setChangeInput(0);
                    setZoom(1);
                },
                catch: err => {
                    if (err) {
                        setErrorsReturned(err);
                        setChangeInput(0);
                    }
                }
            })
        }
    }, [changeInput]);// eslint-disable-line react-hooks/exhaustive-deps

    //remove from formdata for dynamic inputs
    const removeFormData = (single, index, key, model) => {
        setErrorsReturned({});
        if (key === 'nationalities') {
            if (!formData?.nationalities[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (key === 'emails') {
            if (!formData?.emails[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (key === 'phones') {
            if (!formData?.phones[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (key === 'addresses') {
            if (!formData?.addresses[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (!formData[key][index]?.id) {
            let newFd = { ...formData };
            newFd[key].splice(index, 1);
            setFormData(newFd);
        }
        else {
            setDeletePopup({ single, index, key, model });
        }
    }

    const submitDelete = () => {
        let fd = new FormData();
        fd.append('target_id', activeMatter?.id);
        fd.append('relationModel', deletePopup?.model);
        fd.append('relation_id', deletePopup.single.id);

        setGeneralDataApiOptions({
            url: 'delete-litigation-data',
            method: 'post',
            data: fd,
            callback: res => {
                setErrorsReturned({});
                setActiveMatter(res?.data);
                setCurrentInputValue('');
                setCurrentInput('');
                setErrorsReturned({});
                setDeletePopup();
                setChangeInput(0);
            },
            catch: err => {
                if (err) {

                }
            }
        })
    }

    //add new empty field to formdata by key
    const addToFormData = (key, autoFocus) => {
        let emptyObject = {};
        let added = 1;
        if (key === 'nationalities') {
            if (!profileClient?.nationality_id) {
                added = 0;
            }
            if (formData?.nationalities?.length > 0) {
                formData?.nationalities?.forEach(single => {
                    if (single?.nationality_id === null || single?.nationality_id === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: null, nationality_id: null };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }

        if (key === 'emails') {
            if (!profileClient?.email) {
                added = 0;
            }
            if (formData?.emails?.length > 0) {
                formData?.emails?.forEach(single => {
                    if (single?.email === null || single?.email === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, email: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }

        if (key === 'phones') {
            if (!profileClient?.main_phone_number) {
                added = 0;
            }
            if (formData?.phones?.length > 0) {
                formData?.phones?.forEach(single => {
                    if (single?.phone === null || single?.phone === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, phone: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }

        if (key === 'addresses') {
            if (!profileClient?.main_address) {
                added = 0;
            }
            if (formData?.addresses?.length > 0) {
                formData?.addresses?.forEach(single => {
                    if (single?.address === null || single?.address === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, address: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }
        if (key === 'assignees') {
            if (!activeMatter?.assignee) {
                added = 0;
            }
            if (formData?.assignees?.length > 0) {

                formData?.assignees?.forEach(single => {
                    if (single?.name === null || single?.name === '') {

                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, name: '', description: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        } else {
            if (!activeMatter[key]) {
                added = 0;
            }
            if (formData[key]?.length > 0) {

                formData[key]?.forEach(single => {
                    if (single?.name === null || single?.name === '') {

                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, name: '', description: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }

        if (autoFocus) {
            setTimeout(() => {
                setCurrentInput(key)
                setCurrentInputValue({
                    ...currentInputValue, id: 0, name: '', description: '',
                    relationModel: 'LitigationUpdate',
                    relationField: 'litigation_id',
                    firstInputPlaceholder: 'Party Name',
                    firstInputName: 'date',
                    firstInputValue: formatDate(new Date()),
                    secondInputName: 'description',
                    secondInputValue: '',
                    thirdInputValue: '',
                })
            }, 100)
        }
    }

    //cancel delete popup
    const cancelDeletePopup = () => {
        setDeletePopup();
    }


    useEffect(() => {
        setExcludeValues([]);
    }, [formData])

    useEffect(() => {
        if (Number(excludeValues?.length) === 0) {
            if (formData?.clients) {
                formData?.clients?.map((client) => {
                    if (!excludeValues.includes(client?.selectedClient)) {
                        if (Number(client?.id) !== 0) {
                            setExcludeValues(prev => ([...prev, client?.selectedClient?.label]))
                        }
                    }
                    return null;
                })

            }
            if (formData?.opponents) {
                formData?.opponents?.map((opponent) => {
                    if (!excludeValues.includes(opponent?.selectedClient)) {
                        if (Number(opponent?.id) !== 0) {
                            setExcludeValues(prev => ([...prev, opponent?.selectedClient?.label]))
                        }
                    }
                    return null;
                })
            }
            if (formData?.parties) {
                formData?.parties?.map((party) => {
                    if (!excludeValues.includes(party?.selectedClient)) {
                        if (Number(party?.id) !== 0) {
                            setExcludeValues(prev => ([...prev, party?.selectedClient?.label]))
                        }
                    }
                    return null;
                })
            }
        }
    }, [excludeValues]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCurrentInputValue({})

        setGeneralDataApiOptions({
            url: 'directories-list',
            method: 'post',
            data: {
                user_id: activeMatter?.id,
                all: true,
                noEmptyComment: true,
                orderByDate: true,
                main_directory: 3
            },
            callback: res => {
                setReturnedFiles(res.data?.files);
            },
            catch: err => {
                if (err) {
                }
            }
        })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="container-fluid">
                <div className="row client-profile-height">
                    <div className="col-12 col-lg-4 pt-4 drop-shadow-div scrollable-client-profile-height">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center pb-3">
                                <div className="position-relative">
                                    <img className="img-fluid" src={'/assets/images/litigation-profile.png'} alt="profile" />
                                </div>
                            </div>
                            {/* priority  */}
                            <div className="col-auto">
                                <PriorityComponent
                                    selectedPriority={activeMatter?.priority}
                                    priorities={priorities}
                                    api={'edit-litigation-priority'}
                                    target={activeMatter?.id}
                                    targetName={'litigation_id'}
                                    editable={true}
                                />
                            </div>

                            {/* status */}
                            <div className="col-auto ms-auto">
                                <StatusComponent
                                    api={'edit-litigation-status'}
                                    selectedStatus={activeMatter?.status}
                                    statuses={statuses}
                                    target={activeMatter.id}
                                    targetName={'litigation_id'}
                                    editable={true}
                                />
                            </div>

                            <div className="col-12 my-auto align-items-center pb-4">
                                <div className="row">

                                    {/* title */}
                                    <div className="col-12 col-sm-6 col-lg-12 col-xl-10 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/LitigationTitleIcon.svg" alt="name" />
                                                <p className="profile-general-input-title">{generalData?.litigationFixedTitles['litigation-title']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                <EditInput
                                                    placeholder={generalData?.litigationFixedTitles['litigation-title']}
                                                    withPadd={1}
                                                    inputType={'text'}
                                                    postUrl="edit-litigation-data"
                                                    data={activeMatter}
                                                    value={formData?.title ? formData?.title : ''}
                                                    inputName={"title"}
                                                    userId={user_id}
                                                    validation={'required'}
                                                    errorsReturned={errorsReturned}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    {/* number */}
                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/LitigationNumberIcon.svg" alt="name" />
                                                <p className="profile-general-input-title">{generalData?.litigationFixedTitles['litigation-number']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                {/* <EditInput
                                                    placeholder={generalData?.matterSectionsSettings['litigation-number']}
                                                    withPadd={1}
                                                    inputType={'text'}
                                                    inputName={"number"}
                                                    postUrl="edit-client-data"
                                                    data={activeMatter}
                                                    value={formData?.number ? formData?.number : ''}
                                                    userId={user_id}
                                                    validation={'required'}
                                                    errorsReturned={errorsReturned}
                                                /> */}
                                                <p className="ps-4 mb-0">{formData?.number}</p>
                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.foreign_name ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.foreign_name}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                    {/* type  */}
                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/LitigationTypeIcon.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.litigationFixedTitles['litigation-type']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-8 pt-1">
                                                {/* <div style={{ zIndex: "999" }} className="col-auto" onClick={(e) => addToFormData('nationalities')}>
                                                    </div> */}
                                                <Select
                                                    isSearchable={true}
                                                    classNamePrefix="profile-select"
                                                    className="profile-select w-100 my-auto ps-4"
                                                    styles={customStyles}
                                                    options={getOptions(generalData?.litigationTypes, 'slug', 'id', 'title')}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    isDisabled={authUser?.user_role_id > 2 ? true : false}
                                                    placeholder={generalData?.litigationFixedTitles['litigation-type']}
                                                    value={selectedLitigationType ? selectedLitigationType : null}
                                                    onChange={(e) => { handleSelectChange(e, 'type_id', setSelectedLitigationType) }}
                                                />
                                            </div>
                                            {
                                                (errorsReturned && errorsReturned?.nationalities) || (errorsReturned && errorsReturned?.nationality_id) ?
                                                    <>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.nationalities}</p>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.nationality_id}</p>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                    {/* stage */}
                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/LitigationStageIcon.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.mattersCommonTitles['stage']}</p>
                                            </div>
                                            <div className="col-12 pt-1">
                                                {/* <Select
                                                    isSearchable={true}
                                                    classNamePrefix="profile-select"
                                                    className="profile-select w-100 my-auto ps-4"
                                                    styles={customStyles}
                                                    options={getOptions(generalData?.litigationStages, 'slug', 'id', 'title')}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    placeholder={generalData?.matterSectionsSettings['litigation-stage']}
                                                    value={selectedLitigationStage ? selectedLitigationStage : null}
                                                    onChange={(e) => { handleSelectChange(e, 'stage_id', setSelectedLitigationStage) }}
                                                /> */}
                                                <p className="ps-4 mb-0">
                                                    {
                                                        activeMatter?.stages[0]?.stage?.title
                                                    }
                                                </p>
                                            </div>

                                        </div>
                                    </div>

                                    {/* clients  */}
                                    <div className="col-12  pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/LitigationPersonIcon.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.litigationFixedTitles['clients-names']}</p>
                                            </div>
                                            {
                                                formData?.clients?.length > 0 &&
                                                formData?.clients?.map((client, index) => (
                                                    <div className="col-12 pt-1" key={index}>
                                                        <div className="row">
                                                            {
                                                                Number(index) === 0 ?
                                                                    <div style={{}} className="col-auto" onClick={(e) => authUser?.user_role_id <= 2 ? addToFormData('clients') : e.preventDefault()}>
                                                                        <AddIconLt style={{ marginLeft: "2px !important" }} />
                                                                    </div>
                                                                    :
                                                                    <div style={{}} className="col-auto" onClick={(e) => authUser?.user_role_id <= 2 ? removeFormData(client, index, 'clients', 'LitigationClient') : e.preventDefault()}>
                                                                        <RemoveFd />
                                                                    </div>
                                                            }

                                                            <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                                <EditInput
                                                                    className="w-100"
                                                                    withPadd={0}
                                                                    placeholder={generalData?.litigationFixedTitles['clients-names']}
                                                                    singleRec={client}
                                                                    inputType={'user-description'}
                                                                    relationModel='LitigationClient'
                                                                    relationField='litigation_id'
                                                                    data={formData}
                                                                    value={client?.name ? client?.name : ''}
                                                                    inputName={"clients"}
                                                                    userId={user_id}
                                                                    excludeMultipleValues={excludeValues}
                                                                    firstInputPlaceholder={generalData?.litigationFixedTitles['client-name-description']}
                                                                    secondInputPlaceholder={generalData?.litigationFixedTitles['description']}
                                                                    firstInputName={'client_id'}
                                                                    firstInputValue={client?.selectedClient?.value?.split('-')[1]}
                                                                    secondInputName={'description'}
                                                                    secondInputValue={client?.description || ''}
                                                                    selectedRelation={client?.selectedClient || ''}
                                                                    description={client?.description || ''}
                                                                    multiple
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            {
                                                errorsReturned && errorsReturned?.phones ?
                                                    <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.phones}</p>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                    {/* opponents  */}
                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/LitigationPersonIcon.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.litigationFixedTitles['opponents-names']}</p>
                                            </div>
                                            {
                                                formData?.opponents?.length > 0 ?
                                                    formData?.opponents?.map((opponent, index) => (
                                                        <div className="col-12 pt-1" key={index}>
                                                            <div className="row">
                                                                {
                                                                    Number(index) === 0 ?
                                                                        <div style={{}} className="col-auto" onClick={(e) => authUser?.user_role_id <= 2 ? addToFormData('opponents') : e.preventDefault()}>
                                                                            <AddIconLt />
                                                                        </div>
                                                                        :
                                                                        <div style={{}} className="col-auto" onClick={(e) => authUser?.user_role_id <= 2 ? removeFormData(opponent, index, 'opponents', 'LitigationOpponent') : e.preventDefault()}>
                                                                            <RemoveFd />
                                                                        </div>
                                                                }

                                                                <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                                    <EditInput
                                                                        className="w-100"
                                                                        withPadd={0}
                                                                        placeholder={generalData?.litigationFixedTitles['opponent-name']}
                                                                        singleRec={opponent}
                                                                        inputType={'user-description'}
                                                                        relationModel='LitigationOpponent'
                                                                        relationField='litigation_id'
                                                                        data={formData}
                                                                        value={opponent?.name ? opponent?.name : ''}
                                                                        inputName={"opponents"}
                                                                        userId={user_id}
                                                                        excludeMultipleValues={excludeValues}
                                                                        firstInputPlaceholder={generalData?.litigationFixedTitles['opponent-name-description']}
                                                                        secondInputPlaceholder={generalData?.litigationFixedTitles['description']}
                                                                        firstInputName={'client_id'}
                                                                        firstInputValue={opponent?.selectedClient?.value?.split('-')[1]}
                                                                        secondInputName={'description'}
                                                                        secondInputValue={opponent?.description || ''}
                                                                        selectedRelation={opponent?.selectedClient || ''}
                                                                        description={opponent?.description || ''}
                                                                        multiple
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    null
                                            }
                                            {
                                                errorsReturned && (errorsReturned?.addresses || errorsReturned?.main_address) ?
                                                    <>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.addresses}</p>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.main_address}</p>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    {/* additional_information */}
                    <div className="col-12 col-lg-8 pt-4 px-lg-4 pb-5 scrollable-client-profile-height">

                        <div className="row py-3">
                            <div className="col-12">
                                <h1 className="profile-big-title">{generalData?.litigationFixedTitles['additional-informations']}</h1>
                            </div>

                            {/* work area  */}
                            <div className="col-12 col-md-6 pb-2 pb-md-0">
                                <p className="profile-general-input-title pb-1">{generalData?.litigationFixedTitles['work-area']}</p>
                                <EditInput
                                    placeholder={generalData?.litigationFixedTitles['work-area']}
                                    withPadd={0}
                                    inputType={'text'}
                                    postUrl="edit-client-data"
                                    data={activeMatter}
                                    value={formData?.work_area ? formData?.work_area : ''}
                                    inputName={"work_area"}
                                    userId={user_id}
                                />
                            </div>

                            {/* reference number  */}
                            <div className="col-12 col-md-6 pb-2 pb-md-0">
                                <p className="profile-general-input-title pb-1">{generalData?.litigationFixedTitles['reference-number']}</p>
                                <div className="row">
                                    <div className="col-12">
                                        <EditInput
                                            placeholder={generalData?.matterSectionsSettings['reference-number']}
                                            inputType={'text'}
                                            postUrl="edit-client-data"
                                            data={activeMatter}
                                            value={formData?.reference_number ? formData?.reference_number : ''}
                                            inputName={"reference_number"}
                                            userId={user_id}
                                        />
                                        {
                                            errorsReturned && (errorsReturned?.internal_ref) ?
                                                <>
                                                    <p className="m-0 errors-auth-profile">{errorsReturned?.internal_ref}</p>
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* litigation subject */}
                            <div className="col-12 col-md-6 pb-2 pb-md-0">
                                <p className="profile-general-input-title pb-1">{generalData?.litigationFixedTitles['litigation-subject']}</p>
                                <div className="row">
                                    <div className="col-12">
                                        <EditInput
                                            placeholder={generalData?.litigationFixedTitles['litigation-subject']}
                                            inputType={'text'}
                                            postUrl="edit-client-data"
                                            data={activeMatter}
                                            value={formData?.subject ? formData?.subject : ''}
                                            inputName={"subject"}
                                            userId={user_id}
                                        />
                                        {
                                            errorsReturned && (errorsReturned?.internal_ref) ?
                                                <>
                                                    <p className="m-0 errors-auth-profile">{errorsReturned?.internal_ref}</p>
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* assignees  */}
                        <div className="row py-3">
                            <div className="col-12 col-md-6 pb-2">
                                <h1 className="mb-0 profile-big-title pb-1">{generalData?.litigationFixedTitles['assignee-lawyers']}</h1>
                            </div>
                            <div className="col-12" >
                                <div className="w-100 h-100 d-block " style={{ zIndex: 999 }}>
                                    <UsersSelect
                                        url={'/add-edit-assignees'}
                                        activeEditUsers={activeEditUsers}
                                        setActiveEditUsers={setActiveEditUsers}
                                        data={formData}
                                        model={'Litigation'}
                                        users={formData?.assignees}
                                        clientId={''}
                                        editable={authUser?.user_role_id > 2 ? false : true}
                                        allowDeleteAll={1}
                                        className="litigation-user-select m-0" />
                                </div>
                            </div>
                        </div>

                        <div className="row py-3">
                            <h1 className="profile-big-title">{generalData?.litigationFixedTitles['dates-and-numbers']}</h1>

                            {/* establish date */}
                            <div className="col-12 col-md-6 mb-1">
                                <p className="profile-general-input-title">{generalData?.litigationFixedTitles['establish-date']}</p>
                                <div className="col-12 col-lg-10 col-xl-3  position-relative">
                                    <DatePicker
                                        className="birthdate-profile ps-0"
                                        placeholderText={generalData?.litigationFixedTitles['establish-date']}
                                        value={formData.establish_date ? formData.establish_date : ''}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        selected={establishDate}
                                        dateFormat="d MM yyyy"
                                        onChange={(date) => { handleDatePickerChange(date, 'establish_date', setEstablishDate) }}
                                        maxDate={addYears(new Date(), +10)}
                                        minDate={addYears(new Date(), -10)}
                                        locale={localStorage.getItem("BlpLanguage")}
                                        disabled={authUser?.user_role_id > 2 ? true : false}
                                    />
                                </div>
                            </div>

                            {/* establish number  */}
                            <div className="col-12 col-md-6 mb-1">
                                <p className="profile-general-input-title pb-1">{generalData?.litigationFixedTitles['establish-number']}</p>
                                <div className="row w-100">
                                    <div className="col-12 col-lg-10 col-xl-8">
                                        <EditInput
                                            placeholder={generalData?.litigationFixedTitles['establish-number']}
                                            inputType={'text'}
                                            postUrl="edit-client-data"
                                            data={activeMatter}
                                            value={formData?.establish_number ? formData?.establish_number : ''}
                                            inputName={"establish_number"}
                                            userId={user_id}
                                        />
                                        {
                                            errorsReturned && (errorsReturned?.internal_ref) ?
                                                <>
                                                    <p className="m-0 errors-auth-profile">{errorsReturned?.internal_ref}</p>
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* appeal date  */}
                            <div className="col-6">
                                <p className="profile-general-input-title">{generalData?.litigationFixedTitles['appeal-date']}</p>
                                <div className="col-12 col-sm-8 col-lg-10 col-xl-3 position-relative">
                                    <DatePicker
                                        className="birthdate-profile ps-0"
                                        placeholderText={generalData?.litigationFixedTitles['appeal-date']}
                                        value={formData?.appeal_date ? formData?.appeal_date : ''}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        selected={appealDate}
                                        dateFormat="d MM yyyy"
                                        onChange={(date) => { handleDatePickerChange(date, 'appeal_date', setAppealDate) }}
                                        maxDate={addYears(new Date(), +10)}
                                        minDate={addYears(new Date(), -10)}
                                        locale={localStorage.getItem("BlpLanguage")}
                                        disabled={authUser?.user_role_id > 2 ? true : false}
                                    />
                                </div>
                            </div>

                            {/* appeal number  */}
                            <div className="col-6">
                                <p className="profile-general-input-title pb-1">{generalData?.litigationFixedTitles['appeal-number']}</p>
                                <div className="col-12 col-lg-10 col-xl-8">
                                    <EditInput
                                        placeholder={generalData?.litigationFixedTitles['appeal-number']}
                                        inputType={'text'}
                                        postUrl="edit-client-data"
                                        data={activeMatter}
                                        value={formData?.appeal_number ? formData?.appeal_number : ''}
                                        inputName={"appeal_number"}
                                        userId={user_id}
                                    />
                                    {
                                        errorsReturned && (errorsReturned?.internal_ref) ?
                                            <>
                                                <p className="m-0 errors-auth-profile">{errorsReturned?.internal_ref}</p>
                                            </>
                                            :
                                            null
                                    }
                                </div>
                            </div>

                            {/* round date  */}
                            <div className="col-6">
                                <p className="profile-general-input-title">{generalData?.litigationFixedTitles['round-date']}</p>
                                <div className="col-12 col-sm-8 col-lg-10 col-xl-3 position-relative">
                                    <DatePicker
                                        className="birthdate-profile ps-0"
                                        placeholderText={generalData?.litigationFixedTitles['round-date']}
                                        value={formData?.round_date ? formData?.round_date : ''}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        selected={roundDate}
                                        dateFormat="d MM yyyy"
                                        onChange={(date) => { handleDatePickerChange(date, 'round_date', setRoundDate) }}
                                        maxDate={addYears(new Date(), +10)}
                                        minDate={addYears(new Date(), -10)}
                                        locale={localStorage.getItem("BlpLanguage")}
                                        disabled={authUser?.user_role_id > 2 ? true : false}
                                    />
                                </div>
                            </div>

                            {/* round number  */}
                            <div className="col-6">
                                <p className="profile-general-input-title pb-1">{generalData?.litigationFixedTitles['round-number']}</p>
                                <div className="col-12 col-lg-10 col-xl-8">
                                    <EditInput
                                        placeholder={generalData?.litigationFixedTitles['round-number']}
                                        inputType={'text'}
                                        postUrl="edit-client-data"
                                        data={activeMatter}
                                        value={formData?.round_number ? formData?.round_number : ''}
                                        inputName={"round_number"}
                                        userId={user_id}
                                    />
                                    {
                                        errorsReturned && (errorsReturned?.internal_ref) ?
                                            <>
                                                <p className="m-0 errors-auth-profile">{errorsReturned?.internal_ref}</p>
                                            </>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>

                        {/* parties  */}
                        <div className="row py-3 w-100">
                            <h1 className="profile-big-title m-0">
                                {generalData?.litigationFixedTitles['parties']}
                            </h1>
                            <div className="col-xl-6 mt-1 w-100">
                                <p className="profile-general-input-title pb-1">{generalData?.litigationFixedTitles['parties-names']}</p>

                                {
                                    formData?.parties?.length > 0 ?
                                        formData?.parties?.map((party, index) => (
                                            <div className="col-12 pt-1" key={index}>
                                                <div className="row">
                                                    {
                                                        Number(index) === 0 ?
                                                            <>
                                                                <div className="col-auto" onClick={(e) => authUser?.user_role_id <= 2 ? addToFormData('parties') : e.preventDefault()}>
                                                                    <AddIconLt />
                                                                </div>
                                                                {
                                                                    party?.id &&
                                                                    <div className="col-auto ps-0" onClick={(e) => authUser?.user_role_id <= 2 ? removeFormData(party, index, 'parties', 'LitigationParty')  : e.preventDefault()}>
                                                                        <RemoveFd className="mt-0" />
                                                                    </div>
                                                                }
                                                            </>
                                                            :
                                                            <div className="col-auto" onClick={(e) => removeFormData(party, index, 'parties', 'LitigationParty')}>
                                                                <RemoveFd />
                                                            </div>
                                                    }

                                                    <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                        <EditInput
                                                            className="w-100"
                                                            withPadd={0}
                                                            placeholder={generalData?.litigationFixedTitles['parties-names-description']}
                                                            singleRec={party}
                                                            inputType={'user-description'}
                                                            relationModel='LitigationParty'
                                                            relationField='litigation_id'
                                                            data={formData}
                                                            value={party?.name ? party?.name : ''}
                                                            inputName={"parties"}
                                                            userId={user_id}
                                                            excludeMultipleValues={excludeValues}
                                                            firstInputPlaceholder={generalData?.litigationFixedTitles['parties-name-description']}
                                                            secondInputPlaceholder={generalData?.litigationFixedTitles['description']}
                                                            firstInputName={'party_id'}
                                                            firstInputValue={party?.selectedClient?.value?.split('-')[1]}
                                                            secondInputName={'description'}
                                                            secondInputValue={party?.description || ''}
                                                            selectedRelation={party?.selectedClient || ''}
                                                            description={party?.description || ''}
                                                            multiple
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        null
                                }
                            </div>
                        </div>

                        <div className="row py-3">
                            <h1 className="profile-big-title">
                                {generalData?.litigationFixedTitles['description']}
                            </h1>
                            <div className="col-12">
                                <div className="row g-0 p-0 py-4 costs-box-styling custom-shadow px-2">
                                    <div className="col-12" >
                                        <EditInput
                                            inputType={'text-area'}
                                            placeholder={generalData?.litigationFixedTitles['description']}
                                            withPadd={1}
                                            data={activeMatter}
                                            value={formData?.description ? formData?.description : ''}
                                            inputName={"description"}
                                            userId={user_id}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* updates  */}
                        <div className="row py-3">
                            <h1 className="profile-big-title">
                                {generalData?.litigationFixedTitles['latest-updates']}
                            </h1>
                            <div className="col-12">
                                <div className="row g-0 p-0 py-4 costs-box-styling custom-shadow px-2">
                                    {
                                        formData?.updates?.length > 0 &&
                                        formData?.updates?.map((update, index) => (
                                            <div key={index} className="row">
                                                <div style={{}} className="col-auto" onClick={(e) => (authUser?.user_role_id <= 2 || authUser?.id === update?.user_id) ? removeFormData(update, index, 'updates', 'LitigationUpdate') : e.preventDefault()}>
                                                    <RemoveFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                </div>
                                                <div className="col ps-0">
                                                    <UpdatesEditInput
                                                        errorsReturned={errorsReturned}
                                                        setErrorsReturned={setErrorsReturned}
                                                        className="w-100"
                                                        withPadd={0}
                                                        placeholder={generalData?.litigationFixedTitles['latest-updates']}
                                                        singleRec={update}
                                                        inputType={'date-description'}
                                                        relationModel='LitigationUpdate'
                                                        relationField='litigation_id'
                                                        data={formData}
                                                        value={update?.name ? update?.name : ''}
                                                        inputName={"updates"}
                                                        userId={user_id}
                                                        multiple
                                                        firstInputPlaceholder={''}
                                                        firstInputName={'date'}
                                                        firstInputValue={update?.date || formatDate(new Date())}
                                                        
                                                        secondInputPlaceholder={''}
                                                        secondInputName={'description'}
                                                        secondInputValue={update?.description || ''}

                                                        thirdInputPlaceholder={''}
                                                        thirdInputName={'File'}
                                                        thirdInputValue={update?.uploaded_files?.full_path_file ?? null}
                                                    />
                                                </div>
                                            </div>
                                        ))

                                    }

                                    <div className="ms-auto col-auto">
                                        <div className="cursor-pointer" onClick={() => { addToFormData('updates', true) }}>
                                            <AddIconLt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <DocumentsSmallTab userId={user_id} files={profileClient?.home_files} /> */}

                        <ClientComments
                            commentsData={formData?.comments}
                            api={'add-edit-litigation-comment'}
                            deleteApi={'delete-litigation-comment'}
                            relation={'litigation_id'}
                            relation_id={formData?.id}
                            section={'general-info'}
                            setState={(data) => setActiveMatter(data)}
                        />

                        <div className="row py-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h1 className="profile-big-title">
                                    {generalData?.litigationFixedTitles['litigation-logs']}
                                </h1>
                                {
                                    returnedFiles?.length > 5 &&
                                    <a href={`/matters/litigations/${activeMatter?.id}/documents/history`}>{generalData?.fixed_titles['view-all']}</a>
                                }

                            </div>

                            {
                                returnedFiles &&
                                <LogsComponent showCount={returnedFiles?.length} returnedFiles={returnedFiles} generalData={generalData} />
                            }

                        </div>
                    </div>
                </div>

                {
                    //delete popup
                    deletePopup &&
                    <>
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={() => cancelDeletePopup()} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                <button onClick={submitDelete} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                <button onClick={() => cancelDeletePopup()} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <Api options={usersApiOptions} />
            <Api options={generalDataApiOptions} />
        </>
    )
}